import axios from 'axios';
import config from "../config"

class Service{

    getBatches(type){ 

        let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD)
        .concat("/batch".query({type}));

        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    getCourses(){ 

        let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD)
        .concat("/courses/all");

        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    getAttendance(session,batch,course_id){ 

        let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD)
        .concat("/attendance")
        .query({
            batch,
            session,
            course_id,
            paginate:false,
            type:"complete",
            sort:"desc"
        });

        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       });
       
    }

    // createBatch(data){ 
    //     let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/batch");
    //     return new Promise((resolve,reject) => {
    //      axios.post(url,data)
    //      .then(res => {
    //         resolve(res.data);
    //      })
    //      .catch(err => reject(err.message));
    //    })
    // }

    // createSession(batch_id,data){

    //     let url = ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/batch/:batch_id/add-session").bind(batch_id);
        
    //     return new Promise((resolve,reject) => {
    //         axios.post(url,data)
    //         .then(res => {
    //            resolve(res.data);
    //         })
    //         .catch(err => reject(err.message));
    //       })
       
    // };

}

export default new Service();