import React from "react";
import { Navigation } from "../../components/navigation/Navigation";
import StudentForm from "./StudentForm";

function UpdateStudent() {
  return (
    <>
      <Navigation title="Update Student" />
      <StudentForm />
    </>
  );
}

export default UpdateStudent;