import { useState } from "react";
import { BsPencil, BsXLg } from "react-icons/bs";
import BatchService from "./BatchService";
import ClipLoader from "react-spinners/ClipLoader";

function CreateBatch({ batches, setBatches }) {
  
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);


  const handleCreateBatch = (e) => {

    e.preventDefault();
    setLoading(true);

    if (name === "" || description === "") {
      setErr(true);
      return;
    }
    let data = {name,batch_of:description}

    BatchService
    .createBatch(data)
    .then(res => {

      const new_batch = {
        id: res.data.id,
        name: name,
        description: description,
        sessions: [],
      };
      
      setLoading(false);

      setBatches((prev) => [...prev, new_batch]);
      setName("");
      setDescription("");

    });

  
  };

  return (
    <div className="c__modules pb-2">
      <div className="batches_header">
        <h4 className=" p-2">Create Batch</h4>
        <form className="mt-3 p-2" onSubmit={handleCreateBatch}>
          <div className="form-group mb-3">
            <label htmlFor="">Batch Name</label>
            <input type="text" placeholder="Enter Batch Name" className="form-control shadow-none" value={name} onChange={(e) => setName(e.target.value)} />
            {err && name === "" ? <span className="text-danger">Batch Name Required</span> : null}
          </div>
          <div className="form-group mb-3">
            <label htmlFor="">Batch Description</label>
            <input type="text" placeholder="E.g, July 2023" className="form-control shadow-none" value={description} onChange={(e) => setDescription(e.target.value)} />
            {err && description === "" ? <span className="text-danger">Description Required</span> : null}
          </div>
          <div className="form-group">
            <button 
              disabled={loading ? "disabled" : null}
              className="btn btn-outline-secondary">
              {loading ? <ClipLoader color="#0a6bfb" /> : "Create Batch"}
            </button>
          </div>
        </form>
      </div>
      <div className="batch_contents">
        {batches.length ? (
          batches.map((batch) => (
            <div key={batch.id} className="mx-2 mb-3 p-2 shadow-sm border batch_content">
              <div className="d-flex justify-content-between">
                <h5>{batch.name}</h5>
                <div className="btns d-flex">
                  <button className="btn btn-outline-primary me-2">
                    <BsPencil />
                  </button>
                  <button className="btn btn-outline-danger">
                    <BsXLg />
                  </button>
                </div>
              </div>
              <p>Batch of: {batch.description}</p>
            </div>
          ))
        ) : (
          <h5 className="m-2">No Batch Created</h5>
        )}
      </div>
    </div>
  );
}

export default CreateBatch;
