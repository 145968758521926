import { useEffect, useState } from "react";
import { BsCloudUpload, BsPencil, BsPlusSquare, BsXLg } from "react-icons/bs";
import { useAssessmentContext } from "../../hooks/useAssessmentContext";
import AssessmentService from "./AssessmentService";
import { toast } from 'react-toastify';

function CreatePhase({ phases, setPhases, assessId }) {

  const [phaseName, setPhaseName] = useState("");
  const [duration, setDuration] = useState("");
  const [descp, setDescp] = useState("");
  const [resource, setResource] = useState("");
  const { setAssessments, assessments } = useAssessmentContext();

  const createPhase = (e) => {
    e.preventDefault();
    
    // id: Math.floor(Math.random() * 10000000).toString(),
    let newPhase = {
      id:"",
      pool_id:assessId,
      name: phaseName,
      duration,
      descp,
      attachment: false,
      tasks: [],
    };

    let updateAssessment = assessments.map((assess) => {
      if (assess.id === assessId) {
        assess.phases = [...phases, newPhase];
        return assess;
      }
      return assess;
    });

    AssessmentService
    .createProjectPhase(newPhase)
    .then((res)=>{

      if(res.type === 'SUCCESS'){

        newPhase.id = res.data.id;

        AssessmentService
        .uploadResource(res.data.id,resource)
        .then(_=>{

          if(_.type === "SUCCESS"){

            setResource("");
            setPhases((prev) => [...prev, newPhase]);
            setAssessments(updateAssessment);
            setPhaseName("");
            setDuration("");
            setDescp("");

            toast(_.msg, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });


          }

        })


      }

    })

  };


  return (
    <div className="c__modules pb-2">
      <div className="c_modules_header">
        <h4 className=" p-2">Create Phase</h4>
        <form className="mt-3 p-2" onSubmit={createPhase}>
          <div className="form-group mb-2">
            <label htmlFor="">Phase Name</label>
            <div className="c__modules_form_group">
              <input type="text" placeholder="Enter Phase" className="form-control shadow-none" value={phaseName} onChange={(e) => setPhaseName(e.target.value)} />
              <button>
                <BsPlusSquare />
              </button>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="">Duration</label>
            <input type="text" placeholder="Enter Duration" className="form-control shadow-none" value={duration} onChange={(e) => setDuration(e.target.value)} />
          </div>
          <div className="form-group">
            <label htmlFor="">Description</label>
            
            <textarea 
              placeholder="Enter Description" 
              className="form-control shadow-none" 
              value={descp} 
              onChange={(e) => setDescp(e.target.value)} ></textarea>
          </div>
          <div className="form-group course_form_upload mt-3">
            <label htmlFor="phase_attachment">
              <BsCloudUpload />
              <span>{resource ? resource.name : "Upload Thumbnail Image"}</span>
            
            </label>
            <input 
              type="file" 
              id="phase_attachment"
              onChange={(e) => setResource(e.target.files[0])}
               />
          </div>
        </form>
      </div>
      <div className="c_modules_content">
        {phases.length ? (
          phases.map((phase) => (
            <div key={phase.id} className="module_content">
              <h5>{phase.name}</h5>
              <div className="btns d-flex">
                <button className="btn btn-outline-primary me-2">
                  <BsPencil />
                </button>
                <button className="btn btn-outline-danger">
                  <BsXLg />
                </button>
              </div>
            </div>
          ))
        ) : (
          <h5 className="m-2">No Phase</h5>
        )}
      </div>
    </div>
  );
}

export default CreatePhase;
