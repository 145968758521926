import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import BranchService from "./BranchService";
import ClipLoader from "react-spinners/ClipLoader";

export const BranchForm = () => {

  let location = useLocation();

  let initial_state = {
    name: "",
    country: "",
    duration: "",
    state: "",
    district: "",
    location: "",
    landmark: "",
    address: ""
  }

  let [locate, setLocate] = useState("");
  let [loading, setLoading] = useState(false);
  let [data, setData] = useState(initial_state);
  let [errCheck, setErrCheck] = useState(false);

  let handleOnchange = (e) => {
    let { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  }

  let handleCreateBranch = (e) => {
    e.preventDefault();

    if (Object.values(data).filter((val) => val === "")) {
      setErrCheck(true);
    } else {
      setLoading(true);

      BranchService.create(data)
        .then((_) => {


          if (_.type === "SUCCESS") {

            setLoading(false);
            setData(initial_state);

            toast(_.msg, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

          }

        })
    }

  }

  useEffect(() => {
    setLocate(location.pathname);
  }, [location]);

  return (
    <>
      <div className="container">

        <form
          className="form course_form"
          onSubmit={handleCreateBranch}>

          <div className="row">
            <div className="col-lg-12">
              <label htmlFor="">Name</label>
              <input
                type="text"
                placeholder="Enter Name"
                name="name"
                value={data.name}
                onChange={handleOnchange}
                className="form-control shadow-none" />
              {errCheck === true && data.name === "" ? <span className="text-danger">Name Required</span> : null}
            </div>
            <div className="col-lg-12">
              <label htmlFor="">Country</label>
              <input
                type="text"
                name="country"
                value={data.country}
                onChange={handleOnchange}
                placeholder="Enter Country"
                className="form-control shadow-none" />
              {errCheck && data.country === "" ? <span className="text-danger">Country Required</span> : null}
            </div>
            <div className="col-lg-12">
              <label htmlFor="">State</label>
              <input
                type="text"
                name="state"
                value={data.state}
                onChange={handleOnchange}
                placeholder="Enter State"
                className="form-control shadow-none" />
              {errCheck && data.state === "" ? <span className="text-danger">State Required</span> : null}
            </div>
            <div className="col-lg-12">
              <label htmlFor="">District</label>
              <input
                type="text"
                name="district"
                value={data.district}
                onChange={handleOnchange}
                placeholder="Enter District"
                className="form-control shadow-none" />
              {errCheck && data.district === "" ? <span className="text-danger">District Required</span> : null}
            </div>
            <div className="col-lg-12">
              <label htmlFor="">Location</label>
              <input
                type="text"
                name="location"
                value={data.location}
                onChange={handleOnchange}
                placeholder="Enter Location"
                className="form-control shadow-none" />
              {errCheck && data.location === "" ? <span className="text-danger">Location Required</span> : null}
            </div>
            <div className="col-lg-12">
              <label htmlFor="">Landmark</label>
              <input
                type="text"
                name="landmark"
                value={data.landmark}
                onChange={handleOnchange}
                placeholder="Enter Location"
                className="form-control shadow-none" />
              {errCheck && data.landmark === "" ? <span className="text-danger">Landmark Required</span> : null}
            </div>
            <div className="col-lg-12">
              <label htmlFor="">Address</label>
              <textarea
                name="address"
                value={data.address}
                onChange={handleOnchange}
                className="form-control shadow-none"
                placeholder="Enter Address"></textarea>
              {errCheck && data.address === "" ? <span className="text-danger">Address Required</span> : null}
            </div>
            <div className="course_form_btn">
              {locate.includes("update_branch") ? (
                <button
                  disabled={loading ? "disabled" : null}
                  className="btn btn-outline-primary">{loading ? <ClipLoader color="#0a6bfb" /> : " Update Branch"}</button>
              ) : (
                <button
                  disabled={loading ? "disabled" : null}
                  className="btn btn-outline-primary">
                  {loading ? <ClipLoader color="#0a6bfb" /> : " Create Branch"}
                </button>
              )}
            </div>
          </div>

        </form>
      </div>
    </>
  );
};
