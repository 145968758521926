import React, { useState } from "react";
import { Navigation } from "../../components/navigation/Navigation";
import { Link } from "react-router-dom";
import { BsPlusSquare } from "react-icons/bs";
import "./style.css";

function Notifications() {
  const [nofications] = useState([
    {
      id: 1,
      title: "Class Resumption",
      date: "09/11/2023",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eos ipsa, expedita repellendus vitae ad at dolores consequuntur debitis veritatis aperiam tenetur, aute architecto ea fugiat quae. At numquam expedita magni earum dignissimos nemo voluptatibus. Deserunt, eaque fuga! Saepe sapiente, minus culpa expedita quisquam, distinctio ipsum est iste debitis dolorum, reiciendis soluta dicta quae nulla. Aut doloribus ullam error cupiditate ea, quaerat ducimus id quas velit quod aperiam animi consequuntur culpa quos hic. Vitae sed molestias dolorum distinctio delectus excepturi eligendi voluptatibus alias eius ipsum! Aliquid excepturi asperiores autem culpa est maiores. Similique veniam quasi eligendi odio quaerat expedita exercitationem a?",
    },
    {
      id: 2,
      title: "Holiday Break",
      date: "15/12/2023",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eos ipsa, expedita repellendus vitae ad at dolores consequuntur debitis veritatis aperiam tenetur, aute architecto ea fugiat quae. At numquam expedita magni earum dignissimos nemo voluptatibus. Deserunt, eaque fuga! Saepe sapiente, minus culpa expedita quisquam, distinctio ipsum est iste debitis dolorum, reiciendis soluta dicta quae nulla. Aut doloribus ullam error cupiditate ea, quaerat ducimus id quas velit quod aperiam animi consequuntur culpa quos hic. Vitae sed molestias dolorum distinctio delectus excepturi eligendi voluptatibus alias eius ipsum! Aliquid excepturi asperiores autem culpa est maiores. Similique veniam quasi eligendi odio quaerat expedita exercitationem a?",
    },
  ]);
  return (
    <>
      <Navigation title="Notifications" />
      <div className="notifications">
        <div className="d-flex align-items-center justify-content-between px-2 py-4">
          <h4>All Notifications</h4>
          <Link to="/notifications/create_new" className="text-primary">
            <BsPlusSquare /> New Announcement
          </Link>
        </div>
        <div className="notif_container">
          {nofications.length
            ? nofications.map((notif) => (
                <div className="notif_content" key={notif.id}>
                  <div className="notif_content_header">
                    <h5>{notif.title}</h5>
                    <h4>{notif.date}</h4>
                  </div>
                  <p>{notif.description}</p>
                </div>
              ))
            : null}
        </div>
      </div>
    </>
  );
}

export default Notifications;
