import axios from "axios";
import { createContext, useState } from "react";
import config from "../pages/config";

// import { Pagination } from 'flowbite-react';

export const StudentContext = createContext();

export const StudentProvider = ({ children }) => {

  const [students, setStudents] = useState([]);
  const [activeStudents, setActiveStudents] = useState([]);
  const [interestStudents, setInterestStudents] = useState([]);
  const [errMsg, setErrMsg] = useState("No Data Found");
  const [loading, setLoading] = useState(false);

  const getInterests = async () => {
    setStudents([]);
    setLoading(true);
    try {
      const url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/interests");
      //"https://api.reworkacademy.co/v2/interests"
      const resp = await axios.get(url);
      const data = resp.data.map((student) => {
        student.status = "Interests";
        return student;
      });
      setStudents(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrMsg("Could not get data");
    }
  };

  const getAllStudents = async () => {
    setStudents([]);
    setLoading(true);
    try {
      const url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/admin/students?id=123&page=1&limit=20&status=all");
      //"https://api.reworkacademy.co/v2/admin/students?id=123&page=1&limit=20&status=all"
      const resp = await axios.get(url);
      setStudents(resp.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrMsg("Could Not Get Students");
    }
  };

  // const [currentPage, setCurrentPage] = useState(1);

  // const onPageChange = (page) => setCurrentPage(page);

  const values = {
    students,
    setStudents,
    activeStudents,
    setActiveStudents,
    interestStudents,
    setInterestStudents,
    errMsg,
    setErrMsg,
    loading,
    setLoading,
    getInterests,
    getAllStudents,
  };


  return <StudentContext.Provider value={values}>
  
    {/* <div className="flex overflow-x-auto sm:justify-center">
      <Pagination currentPage={currentPage} totalPages={100} onPageChange={onPageChange} />
    </div> */}
    {children}
    </StudentContext.Provider>;
};
