import React from "react";
import { Accordion } from "react-bootstrap";

function PhasesContent({ phases }) {
  return (
    <div className="c__modules_breakdown_display">
      <div className="c_modules_header">
        <h4 className=" p-2">Tasks & Content Breakdown</h4>
      </div>
      <div className="c_modules_breakdown mt-3">
        <Accordion>
          {phases.length ? (
            phases.map((phase_item) => (
              <Accordion.Item eventKey={phase_item.id} key={phase_item.id}>
                <Accordion.Header>{phase_item.name}</Accordion.Header>
                <Accordion.Body>
                  <ul className="c_modules_breakdown_list">
                    {phase_item.tasks.map((task, i) => (
                      <li key={task.id}>{task.title}</li>
                    ))}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            ))
          ) : (
            <h4>No Content</h4>
          )}
        </Accordion>
      </div>
    </div>
  );
}

export default PhasesContent;
