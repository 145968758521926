import { Navigation } from "../../components/navigation/Navigation";

function CreateNotification() {
  return (
    <>
      <Navigation title="Create Notification" />
      <div className="container">
        <form className="form course_form">
          <div className="row">
            <div className="col-lg-12">
              <label htmlFor="">Title</label>
              <input type="text" placeholder="Enter Title" className="form-control shadow-none" />
            </div>
            <div className="col-lg-12">
              <label htmlFor="">Batch</label>
              <select className="form-control shadow-none">
                <option value="">Select Batch</option>
              </select>
            </div>
            <div className="col-lg-12">
              <label htmlFor="">Module</label>
              <select className="form-control shadow-none">
                <option value="">Select Module</option>
              </select>
            </div>
            <div className="col-lg-12">
              <label htmlFor="">Description</label>
              <textarea placeholder="Enter Description" className="form-control shadow-none"></textarea>
            </div>
          </div>
          <div className="course_form_btn">
            <button className="btn btn-outline-primary">Create Notification</button>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateNotification;
