import { useState } from "react";

function CreateTasks({ phases, setPhases }) {
  const [phaseId, setPhaseId] = useState("");
  const [taskItems, setTaskItems] = useState("");

  const handleTaskItems = (e) => {
    setTaskItems((prev) => (prev = e.target.value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let addPhaseContent = phases.map((pha) => {
      if (pha.id === phaseId) {
        return { ...pha, tasks: [...pha.tasks, taskItems] };
      }
      return pha;
    });
    
    setPhases((prev) => (prev = addPhaseContent));
  };

  return (
    <div className="c__modules_breakdown">
      <div className="c_modules_header">
        <h4 className=" p-2">Create Task</h4>
        <form className="mt-3 p-2" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="">Phase name</label>
            <select className="form-control shadow-none" value={phaseId} onChange={(e) => setPhaseId((prev) => (prev = e.target.value))}>
              <option value="">Select Phase</option>
              {phases.map((phase) => (
                <option value={phase.id} key={phase.id}>
                  {phase.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group mt-2">
            <label htmlFor="">Phase Content</label>
            <textarea placeholder="E.g: Home Page, etc.." className="form-control shadow-none" value={taskItems} onChange={handleTaskItems}></textarea>
          </div>
          <div className="form-group mt-3">
            <button className="btn btn-outline-primary">Create</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateTasks;
