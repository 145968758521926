import { Link } from "react-router-dom";
import { Navigation } from "../../components/navigation/Navigation";
import "./style.css";
import { BsEye, BsPlusLg, BsSearch } from "react-icons/bs";
import AssessmentsTable from "./AssessmentsTable";
import { useAssessmentContext } from "../../hooks/useAssessmentContext";
import { useEffect } from "react";
import AssessmentService from './AssessmentService';

function Assessments() {
  const { assessments,setAssessments } = useAssessmentContext();

  useEffect(()=>{

    AssessmentService
    .getProjectPool()
    .then(res=>{
      let data = res.data.map(e=>({
        id:e.id,
        name:e.title,
        category:e.module?.name,
        difficulty_level:e.difficulty_level,
        duration:e.duration,
        type:e.category.replace(/_/g," "),
        attachment:e.has_attachment,
        participants:e.participants,
        description:e.descp,
        phases:[]
      }))
      setAssessments(data)
    })

  },[])

  return (
    <>
      <Navigation title="Manage Assessments" />

      <div className="projects_header d-flex justify-content-between align-items-center">
        <div className="p__header_btns">
          <Link to="/assessments/create_new" className="btn btn-outline-secondary">
            New Assessment <BsPlusLg />
          </Link>
          <Link to="/projects/assigned" className="btn btn-outline-primary">
            Assigned Projects <BsEye />
          </Link>
        </div>
        <div className="p__header_input">
          <input type="text" placeholder="Search" className="form-control shadow-none" />
          <button>
            <BsSearch />
          </button>
        </div>
      </div>
      <AssessmentsTable assessments={assessments} />
    </>
  );
}

export default Assessments;
