import { useState } from "react";
import { Navigation } from "../../components/navigation/Navigation";
import AttendanceBatches from "./AttendanceBatches";
import AttendanceCourses from "./AttendanceCourses";
import AttendanceStudents from "./AttendanceStudents";
import "./style.css";

function Attendance() {
  const [courseId, setCourseId] = useState("");
  const [batchId, setBatchId] = useState("");
  const [session, setSession] = useState("");

  return (
    <>
      <Navigation title="Students Attendance" />
      <div className="attendance p-3">
        <div className="row">
          <AttendanceBatches setBatchId={setBatchId} setSession={setSession} />
          <AttendanceCourses setCourseId={setCourseId} />
          <AttendanceStudents courseId={courseId} batchId={batchId} session={session} />
        </div>
      </div>
    </>
  );
}

export default Attendance;
