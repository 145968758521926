import { BsX } from "react-icons/bs";

function SingleTrainer({ activeTrainerId, trainers, setActiveTrainerId }) {
  const singleTrainer = trainers.find((trainer) => trainer.id === activeTrainerId);
  return (
    <div className="col-lg-5">
      {singleTrainer !== undefined ? (
        <div className="single_trainer">
          <button onClick={() => setActiveTrainerId((prev) => (prev = ""))} className="close_trainar_btn">
            <BsX />
          </button>
          <div className="single_trainer_header">
            <div className="st_header_img">{singleTrainer.img}</div>
            <h2>{singleTrainer.first_name + " " + singleTrainer.last_name}</h2>
            <p>{singleTrainer.email}</p>
            <h4>EMP-ID: {singleTrainer.employee_id}</h4>
            <h5>Performance: {singleTrainer.performance}%</h5>
          </div>
          <div className="single_trainer_content p-52 mt-3">
            <div className="row mb-4">
              <p className="col-lg-5">Phone Number:</p>
              <h5 className="col-lg-7">{singleTrainer.phone}</h5>
            </div>
            <div className="row mb-4">
              <p className="col-lg-5">Home Address:</p>
              <h5 className="col-lg-7">{singleTrainer.address}</h5>
            </div>
            <div className="row">
              <p className="col-lg-5">Current Branch:</p>
              <h5 className="col-lg-7">{singleTrainer.current_branch}</h5>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default SingleTrainer;
