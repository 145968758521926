import { Navigation } from "../../components/navigation/Navigation";
import { useEffect } from "react";
import { useModuleContext } from "../../hooks/useModuleContext";
import CreateModule from "./CreateModule";
import CreateModuleBreakdown from "./CreateModuleBreakdown";
import ModuleContent from "./ModuleContent";
import ModuleService from "./ModuleService";
import "./style.css";


function Modules() {
  const { modules, setModules } = useModuleContext();
  
  useEffect(()=>{

    ModuleService
    .getModules()
    .then(res=>{
      setModules(res)
    })

  },[])

  return (
    <>
      <Navigation title="Manage Modules" />
      <div className="course_modules">
        <div className="row">
          <div className="col-lg-4 mb-3">
            <CreateModule modules={modules} setModules={setModules} />
          </div>
          <div className="col-lg-4 mb-3">
            <CreateModuleBreakdown modules={modules} setModules={setModules} />
          </div>
          <div className="col-lg-4 mb-3">
            <ModuleContent modules={modules} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Modules;
