import { useState } from "react";
import { Loading } from "../../components/loading/Loading";
import { Dropdown } from "react-bootstrap";
import { BsChevronDown } from "react-icons/bs";
import { Link } from "react-router-dom";

export const PaymentTable = ({ payments }) => {
  const [loading] = useState(false);
  const [errMsg] = useState("No Payment");
  const currency = "₦";

  return (
    <div className="table_container px-4">
      <table className="table table-bordered">
        <thead className="table-secondary">
          <tr>
            <th>Students ID</th>
            <th>Students Name</th>
            <th>Amount</th>
            <th>Payment method</th>
            <th>Status</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {payments.length ? (
            payments.map((payment) => (
              <tr key={payment.id}>
                <td>{payment.student_id}</td>
                <td>{payment.full_name}</td>
                <td>
                  {currency}
                  {payment.paid}
                </td>
                <td>{payment.method?.replace("_"," ")}</td>
                <td className={`text-white ${payment.status.toLowerCase() === "approved" ? "bg-success" : "bg-danger"}`}>{payment.status}</td>
                <td>{payment.date_paid}</td>
                <td className="my_dropdown">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <BsChevronDown />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Link className="dropdown-item" to={`/payments/update_payment/${payment.id}`}>
                        Update Receipt
                      </Link>
                      <button className="btn">Approve</button>
                      <Link className="dropdown-item" to={`/payments/receipt/${payment.id}`}>
                        View Receipt
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))
          ) : loading === true ? (
            <tr className="table_loading">
              <td colSpan={11}>
                <Loading />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={11}>{errMsg}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
