import { useEffect, useState } from "react";
import { Loading } from "../../components/loading/Loading";
import { Navigation } from "../../components/navigation/Navigation";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsChevronDown, BsSearch } from "react-icons/bs";
import AssessmentService from './AssessmentService';
import moment from "moment";

function AssignedProjects() {
  const initial_data = [
    {
      id: 1,
      assessment_id: 1,
      name: "Apple Website",
      start_date: "10/10/2023",
      end_date: "15/10/2023",
      duration: "1 week",
      status: true,
      assigned: "Precious Mandy",
      expires: "12D 10H 5S",
      module:{
        id:"1",
        name:"CSS"
      },
      "session":"Weekend",
      batch:"Batch 2",
      phases: [
        { 
          id: "1", 
          name: "Phase One", 
          has_attachment: false, 
          duration: "3 Days",
          start_date:"",
          end_date:"",
          tasks: [
            {
              id:1,
              name:"Home Page"
            }, 
            {
              id:2,
              name:"About Page"
            }]
        },
        { 
          id: "2", 
          name: "Phase Two", 
          has_attachment: false, 
          duration: "3 Days", 
          start_date:"",
          end_date:"",
          tasks: [
            {
              id:1,
              name:"Services Page"
            }, 
            {
              id:2,
              name:"Contact Page"
            }] 
        }
      ]
    }
  ]
  const [assignedProjects,setAssignedProject] = useState(initial_data);
  const [loading] = useState(false);
  const [errMsg] = useState("");


  useEffect(()=>{
    
    AssessmentService
    .getAssessment()
    .then(res=>{

        let data = res.data?.map(e=>({
          id: e.id,
          assessment_id: e.id,
          name: e.title,
          start_date:  moment(e.start_date).format('L'),
          end_date:moment(e.end_date).format('L'),
          duration: e.duration,
          assigned: e.assign_type,
          status:e.status,
          type: e.type,
          session: e.session,
          course:e.course,
          module: e.module,
          pool_id: e.pool_id,
          batch: e.batch,
          total_task: e.total_task,
          total_phase: e.total_phase,
          expires: e.expires,
          phases:[]
        }));
        setAssignedProject(data);
    });

  },[])

  return (
    <>
      <Navigation title="Assigned projects" />

      <div className="assigned_projects_header">
        <input type="text" placeholder="Search Projects..." className="form-control shadow-none" />
        <button>
          <BsSearch />
        </button>
      </div>

      <div className="table_container">
        <table className="table table-bordered">
          <thead className="table-secondary">
            <tr>
              <th>Name</th>
              <th>Start Date / End Date</th>
              <th>Batch / Session / Module</th>
              <th>Duration</th>
              <th>Status</th>
              <th>Assigned Type</th>
              <th>Expires</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {assignedProjects.length ? (
              assignedProjects.map((project) => (
                <tr key={project.id}>
                  <td>{project.name}</td>
                  <td className="bolder">{project.start_date} - {project.end_date}</td>
                  <td>{project.batch} - {project.session} - {project.module.name}</td>
                  <td>{project.duration}</td>
                  <td className={project.status==="ACTIVE" ? "bg-primary text-white" : "bg-danger text-white"}>{project.status}</td>
                  <td>{project.assigned}</td>
                  <td>{project.expires}</td>
                  <td className="my_dropdown">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <BsChevronDown />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Link className="dropdown-item" to={`/assessments/progress/${project.id}`}>
                          View Progress
                        </Link>
                        <Link className="dropdown-item" to={`/assessments/delete/${project.id}`}>
                          Delete Project
                        </Link>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))
            ) : loading === true ? (
              <tr className="table_loading">
                <td colSpan={11}>
                  <Loading />
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan={11}>{errMsg}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default AssignedProjects;
