import axios from 'axios';
import config from "../config"

class Service{

   getStudentById(student_id){
      let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/students/query").query({student_id});
      return new Promise((resolve,reject) => {
       axios.get(url)
       .then(res => {
          resolve(res.data);
       })
       .catch(err => reject(err.message));
     })
   }

    getPayments(url){ 
      
      return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    create(data){ 
        let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/payments/record");
        return new Promise((resolve,reject) => {
         axios.post(url,data)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }


}
const service = new Service();
export default service;