import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { IoCheckmarkSharp, IoCloseSharp } from "react-icons/io5";
import AttendanceService from "./AttendanceService";

function AttendanceStudents({ batchId, courseId,  }) {
  const [studentAttendance,setStudentAttendance] = useState([
    {
      id: 1,
      course_id: "2",
      batch_id: "5",
      session: "weekend",
      fullname: "James Deen",
      attendance: [
        {
          id: 1,
          week: "Week 1",
          days: [
            { day: "Mon", status: "present" },
            { day: "Tue", status: "present" },
            { day: "Wed", status: "present" },
            { day: "Fri", status: "present" },
          ],
        },
        {
          id: 2,
          week: "Week 2",
          days: [
            { day: "Mon", status: "present" },
            { day: "Tue", status: "present" },
            { day: "Wed", status: "absent" },
            { day: "Fri", status: "present" },
          ],
        },
        {
          id: 3,
          week: "Week 3",
          days: [
            { day: "Mon", status: "present" },
            { day: "Tue", status: "present" },
            { day: "Wed", status: "present" },
            { day: "Fri", status: "present" },
          ],
        },
        {
          id: 4,
          week: "Week 4",
          days: [
            { day: "Mon", status: "absent" },
            { day: "Tue", status: "present" },
            { day: "Wed", status: "present" },
            { day: "Fri", status: "present" },
          ],
        },
      ],
    },
    {
      id: 2,
      course_id: "1",
      batch_id: "4",
      session: "weekday",
      fullname: "Marcus Jones",
      attendance: [
        {
          id: 1,
          week: "Week 1",
          days: [
            { day: "Mon", status: "present" },
            { day: "Tue", status: "present" },
            { day: "Wed", status: "present" },
            { day: "Fri", status: "present" },
          ],
        },
        {
          id: 2,
          week: "Week 2",
          days: [
            { day: "Mon", status: "present" },
            { day: "Tue", status: "present" },
            { day: "Wed", status: "absent" },
            { day: "Fri", status: "present" },
          ],
        },
        {
          id: 3,
          week: "Week 3",
          days: [
            { day: "Mon", status: "present" },
            { day: "Tue", status: "present" },
            { day: "Wed", status: "present" },
            { day: "Fri", status: "present" },
          ],
        },
        {
          id: 4,
          week: "Week 4",
          days: [
            { day: "Mon", status: "absent" },
            { day: "Tue", status: "present" },
            { day: "Wed", status: "present" },
            { day: "Fri", status: "present" },
          ],
        },
      ],
    },
  ]);


  useEffect(()=>{
    let arr= batchId.split('-');
    let batch = arr[0]?.replace(" ","_");
    let session = arr[1];


    AttendanceService
    .getAttendance(session, batch,courseId)
    .then(res=>{
        let users={};
        let days={};

        res.map(e=>{

          let day_of_week = new Date(e.timestamp).toString().substr(0,3);

          if(!days[e?.user_id]){
            days[e.user_id] = {}
            days[e.user_id]['week '+e.week] = [{id:e.id,day:day_of_week,status:e.status.toLowerCase()}]
         
          }else{
            if(days[e.user_id]['week '+e.week]){
              days[e.user_id]['week '+e.week].push({id:e.id,day:day_of_week,status:e.status.toLowerCase()})
            }else{
              days[e.user_id]['week '+e.week] = [{id:e.id,day:day_of_week,status:e.status.toLowerCase()}]
            }
          }


          if(!users[e?.user_id]) users[e.user_id] = {
            id:e.id,
            session:e.session,
            fullname:e.full_name,
            attendance:[]
          }
          
          let arr = []
          let id = 1;
          for(var [a,b] of Object.entries(days[e.user_id])){
          
            arr.push({
              id,
              week:a,
              days:b
            });
            id++
          }
          users[e.user_id].attendance = arr
        
        });
        let data = Object.values(users)
    console.log(data)
        setStudentAttendance(data)
    });

  },[batchId,courseId])

  return (
    <div className="col-lg-4">
      <div className="attendance_contents">
        <div className="attendance_batches_header">
          <h5>Attendance</h5>
          <p>Students attendance View</p>
        </div>

        {batchId !== "" && courseId !== "" ? (
          studentAttendance.length ? (
            studentAttendance.map((attendance) => (
              <div key={attendance.id} className="px-3 pb-3">
                <Accordion>
                  <Accordion.Item eventKey={attendance.id} key={attendance.id}>
                    <Accordion.Header>{attendance.fullname}</Accordion.Header>
                    <Accordion.Body>
                      <div className="attendance_in_weeks_contents">
                        {attendance.attendance.map((attend) => (
                          <div className="attendance_in_weeks_content" key={attend.id}>
                            <h5>{attend.week}</h5>
                            <div className="attendance_days">
                              {attend.days.map((week, i) => (
                                <div key={i} className="attendance_day">
                                  <p>{week.day}</p>
                                  {week.status === "present" ? (
                                    <button className="attendance_day_present">
                                      <IoCheckmarkSharp />
                                    </button>
                                  ) : (
                                    <button className="attendance_day_absent">
                                      <IoCloseSharp />
                                    </button>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            ))
          ) : (
            <p className="px-3 pb-3 text-center">No Attendance Available</p>
          )
        ) : (
          <p className="px-3 pb-3 text-center">No Attendance selected</p>
        )}
      </div>
    </div>
  );
}

export default AttendanceStudents;
