import { Accordion } from "react-bootstrap";
import { BsXLg } from "react-icons/bs";

function BatchesBreakdown({ batches }) {
  return (
    <div className="c__modules_breakdown_display">
      <div className="c_modules_header">
        <h4 className=" p-2">Batches Breakdown</h4>
      </div>
      <div className="c_modules_breakdown mt-3">
        <Accordion>
          {batches.length ? (
            batches.map((batch) => (
              <Accordion.Item eventKey={batch.id} key={batch.id}>
                <Accordion.Header>{batch.name}</Accordion.Header>
                <Accordion.Body>
                  <ul className="c_modules_breakdown_list batch_breakdown_list">
                    {batch.sessions.map((session) => (
                      <li key={session.id} className="d-flex justify-content-between align-items-center">
                        {session.session_name}
                        <span>Start: {session.start_date}</span>
                        <button className="btn btn-outline-danger p-1">
                          <BsXLg />
                        </button>
                      </li>
                    ))}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            ))
          ) : (
            <h4>No Content</h4>
          )}
        </Accordion>
      </div>
    </div>
  );
}

export default BatchesBreakdown;
