import { Card1 } from "../../components/cards/Card";
import { BsPersonExclamation, BsPeople, BsStack, BsBook, BsPercent } from "react-icons/bs";
import "./style.css";
import { Navigation } from "../../components/navigation/Navigation";
function Dashboard() {
  return (
    <>
      <Navigation title="Dashboard" />
      <div className="dashboard">
        <div className="dashboard_header">
          <Card1 title="New Interests" content="100" icon={<BsPersonExclamation />} />
          <Card1 title="Students" content="100" icon={<BsPeople />} />
          <Card1 title="Batches" content="15" icon={<BsStack />} />
          <Card1 title="Courses" content="5" icon={<BsBook />} />
          <Card1 title="Revenue" content="₦1,000,000" icon={<BsPercent />} />
        </div>
      </div>
    </>
  );
}
export default Dashboard;
