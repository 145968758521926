import React from "react";
import { BranchForm } from "./BranchForm";
import { Navigation } from "../../components/navigation/Navigation";

function CreateBranch() {
  return (
    <>
      <Navigation title="Create Branch" />
      <BranchForm />
    </>
  );
}

export default CreateBranch;
