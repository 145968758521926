import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

function StudentForm() {
  const location = useLocation();
  const [locate, setLocate] = useState("");

  useEffect(() => {
    setLocate(location.pathname);
  }, [location]);

  return (
    <div className="container">
      <form className="student_form">
        <div className="row">
          <div className="col-lg-6">
            <div className="student_form_header">
              <h4>Basic Profile</h4>
            </div>
            <div className="form-group">
              <label htmlFor="">First name</label>
              <input type="text" className="form-control shadow-none" />
            </div>
            <div className="form-group">
              <label htmlFor="">Last name</label>
              <input type="text" className="form-control shadow-none" />
            </div>
            <div className="form-group">
              <label htmlFor="">Other name</label>
              <input type="text" className="form-control shadow-none" />
            </div>
            <div className="form-group">
              <label htmlFor="">Email Address</label>
              <input type="text" className="form-control shadow-none" />
            </div>
            <div className="form-group">
              <label htmlFor="">Phone Number</label>
              <input type="text" className="form-control shadow-none" />
            </div>
            <div className="form-group">
              <label htmlFor="">Address</label>
              <textarea className="form-control shadow-none"></textarea>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="student_form_header">
              <h4>Course / Batch Info</h4>
            </div>
            <div className="form-group">
              <label htmlFor="">Gender</label>
              <select className="form-control shadow-none">
                <option value="">Select</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="">Date of Birth</label>
              <input type="date" className="form-control shadow-none" />
            </div>
            <div className="form-group">
              <label htmlFor="">Marital Status</label>
              <select className="form-control shadow-none">
                <option value="">Select</option>
                <option value="married">Married</option>
                <option value="single">Single</option>
                <option value="divorced">Divorced</option>
                <option value="widowed">Widowed</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="">Branch</label>
              <select className="form-control shadow-none">
                <option value="">Select</option>
                <option value="">Aknaton Estate, Durumi</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="">Batch</label>
              <select className="form-control shadow-none">
                <option value="">Select</option>
                <option value="batch_10">Batch 10</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="">Sub Batch</label>
              <select className="form-control shadow-none">
                <option value="">Select</option>
                <option value="weekday">Weekday</option>
                <option value="weekend">Weekend</option>
                <option value="Custom">Custom</option>
              </select>
            </div>
          </div>
        </div>
        <div className="text-center mt-4">
          {locate.includes("update_student") ? (
            <button className="btn btn-outline-primary">Update Student</button>
          ) : (
            <button className="btn btn-outline-primary">Create Student</button>
          )}
        </div>
      </form>
    </div>
  );
}

export default StudentForm;
