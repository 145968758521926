import { useState } from "react";
import { BsPencil, BsXLg } from "react-icons/bs";
import ModuleService from "./ModuleService";
import ClipLoader from "react-spinners/ClipLoader";

function CreateModule({ modules, setModules }) {

  const initial_data = {
    id:"",
    name: "",
    is_new: "false",
    descp: "",
    objectives: [],
    
  }
  const [record, setRecord] = useState(initial_data);
  const [loading, setLoading] = useState(false);

  const handleOnchange = (e) => {
    let { name, value } = e.target;
    setRecord( { ...record, [name]: value });
  }

  const createModule = (e) => {
    e.preventDefault();

    setLoading(true);
   
    ModuleService
    .createModule(record)
    .then((res)=>{

      setLoading(false);

      if(res.type === 'SUCCESS'){
        let {id} = res.data
        let newModule = {...record,['id']: id,contents: []};
      
        setModules((prev) => [...prev, newModule]);
        setRecord(initial_data);
      
      }
    });
   
  };

  return (
    <div className="c__modules pb-2">
      <div className="c_modules_header">
        <h4 className=" p-2">Create Module</h4>

        <form
          className="mt-3 p-2"
          onSubmit={createModule}>
          <div>
            <label htmlFor="">Module Name</label>
            <div className="">
              <input
                type="text"
                name="name"
                placeholder="Enter Module Name"
                className="form-control shadow-none"
                value={record.name}
                onChange={handleOnchange} />
            </div>
          </div>
          <div>
            <label htmlFor="">Module Description</label>
            <div className="">
              <textarea
                name="descp"
                placeholder="Enter Module Description"
                className="form-control shadow-none"
                value={record.descp}
                onChange={handleOnchange} ></textarea>
            </div>
          </div>
          <div>
            <label htmlFor="">Is Module new</label>
            <div className="flex space-between">
              <span>
                <input
                  name="is_new"
                  value="false"
                  id="no"
                  type="radio"
                  checked={record.is_new === "false" ? true : false}
                  onChange={handleOnchange} />
                  <label htmlFor="no">No</label>
              </span>
              <span>
                <input
                name="is_new"
                value="true"
                id="yes"
                type="radio"
                checked={record.is_new === "true" ? true : false}
                onChange={handleOnchange} />
                 <label htmlFor="yes">Yes</label>
              </span>
            </div>
          </div>
          <div>
            <label htmlFor="">Module Objectives</label>
            <div className="">
              <textarea
                name="objectives"
                placeholder="Enter Module Objectives, separated by commas"
                className="form-control shadow-none"
                value={record.objectives}
                onChange={handleOnchange} ></textarea>
            </div>
          </div>
          <div className="form-group mt-3">
            <button 
              disabled={loading ? "disabled" : null}
              className="btn btn-outline-primary">
                {loading ? <ClipLoader color="#0a6bfb" /> : "Create"}
              </button>
          </div>
        </form>

      </div>
      <div className="c_modules_content">
        {modules.length ? (
          modules.map((module) => (
            <div key={module.id} className="module_content">
              <h5>{module.name}</h5>
              <div className="btns d-flex">
                <button className="btn btn-outline-primary me-2">
                  <BsPencil />
                </button>
                <button className="btn btn-outline-danger">
                  <BsXLg />
                </button>
              </div>
            </div>
          ))
        ) : (
          <h4>No Modules</h4>
        )}
      </div>
    </div>
  );
}

export default CreateModule;
