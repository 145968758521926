import { useEffect, useState } from "react";
import AttendanceService from "./AttendanceService";

function AttendanceCourses({ setCourseId }) {
  const [courses,setCourses] = useState([
    { id: "1", course: "Full Stack Web Development with Node JS" },
    { id: "2", course: "Frontend Development with React JS" },
    { id: "3", course: "Data Analytics with Excel and Power BI" },
    { id: "4", course: "Data Science with Machine Learning & Python" },
    { id: "5", course: "Cyber Security" },
    { id: "6", course: "UI / UX Design" },
  ]);

  const handleCourse = (e) => {
    setCourseId((prev) => (prev = e.target.value));
  };

  useEffect(()=>{

    AttendanceService
    .getCourses()
    .then(res=>{
      let data = res.map(e=>({
        id: e.id,
        course: e.name
      }));

      setCourses(data);

    });

  },[])

  return (
    <div className="col-lg-4">
      <div className="attendance_contents">
        <div className="attendance_batches_header">
          <h5>Courses</h5>
          <p>Select course to view students attendance</p>
        </div>
        <div className="attendance_courses px-2 pb-3">
          <select 
            className="form-control shadow-none" 
            onChange={handleCourse}>
            <option value="">Select Course</option>
            {courses.length
              ? courses.map((course) => (
                  <option value={course.id} key={course.id}>
                    {course.course}
                  </option>
                ))
              : null}
          </select>
        </div>
      </div>
    </div>
  );
}

export default AttendanceCourses;
