import { useState } from "react";
import ModuleService from "./ModuleService";
import ClipLoader from "react-spinners/ClipLoader";

function CreateModuleBreakdown({ setModules, modules }) {
  const initial_data = { 
    id: "", 
    title: "", 
    topics: ""
  };
  const [moduleId, setModuleId] = useState("");
  const [content, setContent] = useState(initial_data);
  const [loading, setLoading] = useState(false);

  const handleContent = (e) => {
    let content = e.target.value;
    let breakword = content.split(",");
    setContent((prev) => ({ ...prev, topics: breakword }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    setLoading(true);

    let content_data = { module_id:moduleId,title:content.title };
   
    ModuleService
    .createCourseContent(content_data)
    .then(res=>{

      setLoading(false);
      
      if(res.type === 'SUCCESS'){

        let {id} = res.data;
        let topic_data = { course_content_id:id,topic:content.topics };
       
        setContent({...content,id})
       
        ModuleService
        .createCourseContentTopic(topic_data)
        .then(res =>{

          if(res.type === 'SUCCESS'){
           
            let record = res.data.id.map(e=>({
                id:e.id,
                topic:e.topic
            }))

             let addModuleContent = modules.map((modu) => {
             
              if (modu.id === moduleId) {
                content.id = id;
                content.topics = record;

                return { ...modu, contents: [...modu.contents,content] };

              }
              return modu;
            });
            console.log("new:",addModuleContent);
            setModules((prev) => (prev = addModuleContent));
            setContent(initial_data);
            // setContent((prev) => ({ ...prev, id: null, title: "", topics: "" }));

          }

        })

      }
    })

   
  };

  return (
    <div className="c__modules_breakdown">
      <div className="c_modules_header">
        <h4 className=" p-2">Create Module Content</h4>
        <form className="mt-3 p-2" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="">Module</label>

            <select 
              className="form-control shadow-none" 
              value={moduleId} 
              onChange={(e) => setModuleId((prev) => (prev = e.target.value))}>
              <option value="">Select Module</option>
              {modules.map((mod) => (
                <option value={mod.id} key={mod.id}>
                  {mod.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group mt-2">
            <label htmlFor="">Title</label>
            <input
              type="text"
              placeholder="Enter Title"
              className="form-control shadow-none"
              value={content.title}
              onChange={(e) => setContent((prev) => ({ ...prev, title: e.target.value }))}
            />
          </div>
          <div className="form-group mt-2">
            <label htmlFor="">Topic (seperate each content by comma)</label>
            <textarea
              placeholder="E.g: Database Design, Database Modelling, etc.."
              className="form-control shadow-none"
              value={content.topics}
              onChange={handleContent}
            ></textarea>
          </div>
          <div className="form-group mt-3">
            <button 
              disabled={loading ? "disabled" : null}
              className="btn btn-outline-primary"> 
              {loading ? <ClipLoader color="#0a6bfb" /> : "Create"}
              </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateModuleBreakdown;
