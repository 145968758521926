import { Navigation } from "../../components/navigation/Navigation";
import StudentForm from "./StudentForm";

function CreateNewStudent() {
  return (
    <>
      <Navigation title="Create New Student" />
      <StudentForm />
    </>
  );
}

export default CreateNewStudent;
