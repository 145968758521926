import { Accordion } from "react-bootstrap";
import { Navigation } from "../../components/navigation/Navigation";
import { useModuleContext } from "../../hooks/useModuleContext";
import { BsPencil, BsTrash } from "react-icons/bs";
import { useState } from "react";

function ManageCourseModules() {

  const [selectedModule, setSelectedModule] = useState("");
  const [errCheck, setErrCheck] = useState(false);
  const { modules } = useModuleContext();
  const [courseModule, setCourseModule] = useState([]);

  const handleAddMoudule = (e) => {
    e.preventDefault();

    if (selectedModule === "") {
      setErrCheck(true);
      return;
    }
    const findModule = modules.find((module) => module.id.toString() === selectedModule);
    const checkIfExists = [...courseModule].find((module) => module.id === findModule.id);

    if (checkIfExists === undefined) {
      setCourseModule((prev) => [...prev, findModule]);
    } else {
      alert("Module Already Exists");
    }

  };
  
  return (
    <>
      <Navigation title="Manage Modules" />
      <div className="container row">
        <div className="mangege_course_modules_form col-lg-6">
          <form>
            <div className="form-group">
              <label htmlFor="">Add Module</label>
              <select className="form-control shadow-none" value={selectedModule} onChange={(e) => setSelectedModule(e.target.value)}>
                <option value="">Select</option>
                {modules.length
                  ? modules.map((module) => (
                      <option value={module.id} key={module.id}>
                        {module.name}
                      </option>
                    ))
                  : null}
              </select>
              {errCheck && selectedModule === "" ? <span>Select a module</span> : null}
            </div>
            <div className="form-group-btn mt-3">
              <button className="btn btn-outline-primary" onClick={handleAddMoudule}>
                Add Module
              </button>
            </div>
          </form>
        </div>
        <div className="mangege_course_modules_breakdown col-lg-6">
          <div className="c__modules_breakdown_display">
            <div className="c_modules_header courseModuleBreakdownHeader">
              <h4 className=" p-2">Module & Content Breakdown</h4>
            </div>
            <div className="c_modules_breakdown courseModuleBreakdown mt-3">
              <Accordion>
                {courseModule.length ? (
                  courseModule.map((content_item) => (
                    <Accordion.Item eventKey={content_item.id} key={content_item.id}>
                      <Accordion.Header>{content_item.name}</Accordion.Header>
                      <Accordion.Body>
                        {content_item?.contents.map((c) => (
                          <div key={c.id}>
                            <h5 className="shadow-sm p-2 d-flex justify-content-between align-items-center">
                              <span>
                                {c.day}: {c.title}
                              </span>
                              <div className="d-flex c_modules_breakdown_icons">
                                <button className="btn btn-outline-primary">
                                  <BsPencil />
                                </button>
                                <button className="btn btn-outline-danger">
                                  <BsTrash />
                                </button>
                              </div>
                            </h5>
                            <ul className="c_modules_breakdown_list">
                              {c.topics.map((item) => (
                                <li key={item.id}>{item.topic}</li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))
                ) : (
                  <h4>No Module</h4>
                )}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageCourseModules;
