import { Dropdown } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";

function TrainersGrid({ trainers, activeTrainerId, setActiveTrainerId }) {
  return (
    <div className={`trainers_grid_container ${activeTrainerId ? "col-lg-7" : "col-lg-12"}`} title="View Trainer">
      {trainers.length ? (
        <div className="trainers_grid row">
          {trainers.map((trainer) => (
            <div className={`mb-3 ${activeTrainerId ? "col-lg-6" : "col-lg-4"}`} key={trainer.id} onClick={() => setActiveTrainerId(trainer.id)}>
              <div className="trainers_grid_content d-flex align-items-center">
                <div className="trainers_grid_dropdown">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <BsThreeDots />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Link className="dropdown-item" to={`/trainers/${trainer.id}/update`}>
                        Update Trainer
                      </Link>
                      <Link className="dropdown-item" to={`/trainers/${trainer.id}/assign`}>
                        Assign Class
                      </Link>
                      <Link className="dropdown-item" to={`/trainers/${trainer.id}/unassign`}>
                        Unassign Class
                      </Link>
                      <Link className="dropdown-item" to="/trainers/suspend">
                        Suspend Trainer
                      </Link>
                      <Link className="dropdown-item" to="/trainers/suspend">
                        Delete Trainer
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="trainers_grid_img">{trainer.img}</div>
                <div className="trainers_grid_descrp">
                  <h5>{trainer.first_name + " " + trainer.last_name}</h5>
                  <p>{trainer.email}</p>
                  <p>{trainer.phone}</p>
                  <p className={trainer.performance <= 40 ? "text-danger" : trainer.performance <= 60 ? "text-warning" : "text-primary"}>{trainer.performance}%</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No trainer Created</p>
      )}
    </div>
  );
}

export default TrainersGrid;
