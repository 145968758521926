import { useEffect, useState } from "react";
import { Navigation } from "../../components/navigation/Navigation";
import CreatePhase from "./CreatePhase";
import CreateTasks from "./CreateTasks";
import PhasesContent from "./PhasesContent";
import { useParams } from "react-router-dom";
import { useAssessmentContext } from "../../hooks/useAssessmentContext";
import AssessmentService from "./AssessmentService";

function ManageTasks() {

  const { id } = useParams();
  const { assessments, setAssessments } = useAssessmentContext();
  const [phases, setPhases] = useState([]);

  useEffect(() => {

    AssessmentService
      .getProjectPhase(id)
      .then(res => {

        if (res.phases) {

          let phases = res.phases.map(e => ({
            id: e.id,
            name: e.title,
            has_attachment: e.resource ? true : false,
            duration: e.duration,
            tasks: e.tasks?.map(t => ({
              id: t.id,
              title: t.descp,
            })) || []
          }))

          if (
            assessments.length === 0 ||
            assessments[0].id === "1") {

            AssessmentService
              .getProjectPool()
              .then(res => {
                let data = res.data.map(e => ({
                  id: e.id,
                  name: e.title,
                  category: e.module_alias,
                  duration: e.duration,
                  type: e.category.replace(/_/g, " "),
                  attachment: e.has_attachment,
                  participants: e.participants,
                  description: e.descp,
                  phases: []
                }))
                setAssessments(data)

                {
                  [...data].map((assessments) => {
                    if (assessments.id === id) {
                      console.log('insider')
                      return setPhases(phases);
                    }
                    return setPhases([]);
                  });

                }


              });

          } else {

            [...assessments].map((assessments) => {
              if (assessments.id === id) {
                console.log('inside')
                return setPhases(phases);
              }
              return setPhases([]);
            });

          }
        }
      })
    // console.log(assessments)



  }, [id]);

  return (
    <>
      <Navigation title="Manage Tasks" />
      <div className="manage_tasks">
        <div className="row">
          <div className="col-lg-4">
            <CreatePhase phases={phases} setPhases={setPhases} assessId={id} />
          </div>
          <div className="col-lg-4">
            <CreateTasks phases={phases} setPhases={setPhases} assessId={id} />
          </div>
          <div className="col-lg-4">
            <PhasesContent phases={phases} />
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageTasks;
