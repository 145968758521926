import { Navigation } from "../../components/navigation/Navigation";
import { PaymentForm } from "./PaymentForm";

function CreatePayment() {
  return (
    <>
      <Navigation title="Create Payment" />
      <PaymentForm />
    </>
  );
}

export default CreatePayment;
