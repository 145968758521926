import { useEffect, useState } from "react";
import { Navigation } from "../../components/navigation/Navigation";
import { useAssessmentContext } from "../../hooks/useAssessmentContext";
import { useSearchParams } from "react-router-dom";
import AssessmentService from './AssessmentService';
import { toast } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";

function AssignProject() {

  const [searchParams, setSearchParams] = useSearchParams();

  const { assessments,setAssessments } = useAssessmentContext();
  // const { modules,setModules } = useModuleContext();
  const [type, setType] = useState("");
  const [phases, setPhases] = useState([]);
  const [disabled,setDisabled] = useState(false);
  const [user,setUser] = useState({})
  const [trainer_id,setTrainerId] = useState({})
  const [trainers,setTrainers] = useState([])
  const [selectedProject,setSelectedProject] = useState("")
  const [selectedProjectType,setSelectedProjectType] = useState("")
  const [loading, setLoading] = useState(false);

  const user_id = searchParams.get("uid");


  const handleType = (e) => {
    setType(e.target.value);
  };

  const handleSetPhases = (e) => {

    let ass = [...assessments]
    let assessment = ass.find((a) => e.target.value === a.id);
    let phases = assessment ? [...assessment.phases] : [];
   
    setSelectedProject(e.target.value);
    setSelectedProjectType(assessment.type.replace(/ /g,'_'))

    if(phases.length  <= 0) {

      AssessmentService
      .getProjectPhase(e.target.value)
      .then(res=>{

        let data = res?.phases.map(e=>({
          id:e.id,
          name:e.title,
          has_attachment:e.resource ? true : false,
          duration:e.duration,
          start_date:"",
          end_date:"",
          submission_trials:"",
          tasks:e.tasks ? e.tasks.map(t=>({
            id:t.id,
            title:t.descp,
          })) : []
        }));

        assessment.phases = data;

        setPhases(data);
        setAssessments(ass);

      })
    }

  };

  let handlePhaseOnChange = (e,phase,key)=>{

    let ass = [...assessments]
    let assessment = ass.find((a) => a.id === selectedProject);
    let phases = [...assessment.phases];
    let _phase = phases.find(x=>x.id === phase.id);

    _phase[key] = e.target.value;

    if(_phase?.start_date && _phase?.end_date){
      _phase.duration = (new Date(_phase.end_date) - new Date(_phase.start_date)) / (1000 * 3600 * 24) +" day(s)";
    }

    setPhases(phases);
  }

  let handleSubmit =(e) =>{
    e.preventDefault();

    setLoading(true);

    let assigned_to ="";

    if(type.toUpperCase() === "SINGLE"){
      assigned_to = 'INDIVIDUAL'
    }

    let data = {
      pool_id: selectedProject,
      assigned_to,
      assessment_type:selectedProjectType,
      user_id:user.id,
      course_id:user.course_id,
      trainer_id,
      phases:phases.map(e=>({
        phase_id:e.id,
        submission_trials:e.submission_trials,
        start_date:e.start_date,
        end_date:e.end_date
      }))
    }

    AssessmentService
    .assignAssessment(data)
    .then(res=>{
    
      if(res.type === 'SUCCESS'){

        setLoading(false);

        toast(res.msg, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setPhases([])

      }else{
        //error
      }
    })

  }

  useEffect(() => {

    if (user_id) {

      setType("Single")

      AssessmentService
      .getStudent(user_id)
      .then((res)=>{

        if(res.id){
    
          setUser({
            id:res.id,
            full_name: res.full_name,
            course_id:res.course?.id
          });
          setDisabled(true);

        }

      })

      // AssessmentService
      // .getTrainer()
      // .then((res)=>{
      //   setTrainers(res)
      // })

    }

    if(!phases.length){
      AssessmentService
      .getProjectPool()
      .then(res=>{
        let data = res.data.map(e=>({
          id:e.id,
          name:e.title,
          category:e.module?.name,
          difficulty_level:e.difficulty_level,
          duration:e.duration,
          type:e.category.replace(/_/g," "),
          attachment:e.has_attachment,
          participants:e.participants,
          description:e.descp,
          phases:[]
        }))
        setAssessments(data)
      });

    }


  }, [])



  return (
    <>
      <Navigation title="Assign Project" />
      <div className="assign_project">
        <form className="form project_form" onSubmit={handleSubmit}>
          <div className="row">
          {/* <div className="col-lg-12 mt-3">
              <label htmlFor="">Module</label>
              <select className="form-control shadow-none">
                <option value="">Select</option>
                {modules.length
                  ? modules.map((module) => (
                    <option value={module.id} key={module.id}>
                      {module.name}
                    </option>
                  ))
                  : null}
              </select>
            </div> */}

            <div className="col-lg-12 mt-3">
              <label htmlFor="">Assessment Name</label>
              <select 
                className="form-control shadow-none" 
                value={selectedProject}
                onChange={handleSetPhases}>
                <option value="">Select</option>
                {assessments.length
                  ? assessments.map((assessment) => (
                    <option value={assessment.id} key={assessment.id}>
                      {assessment.name} -  {assessment.category}  - {assessment.difficulty_level}
                    </option>
                  ))
                  : null}
              </select>
            </div>
            <div className="col-lg-12 mt-3">
              <label htmlFor="">Trainer</label>
              <select 
                className="form-control shadow-none" 
                value={trainer_id}
                onChange={e=> setTrainerId(e.target.value)}>
                <option value="">Select</option>
                {trainers.length
                  ? trainers.map((t) => (
                    <option value={t.id} key={t.id}>
                      {t.first_name} {t.last_name}
                    </option>
                  ))
                  : null}
              </select>
            </div>

            <div className="col-lg-12 mt-3">
              <label htmlFor="">Type</label>
              <select 
                value={type}
                className="form-control shadow-none" 
                onChange={handleType}>
                  <option value="">Select</option>
                  <option value="Single">Single</option>
                  <option value="Batch">Batch</option>
              </select>
            </div>
            {type === "Single" ? (
              <div className="col-lg-12 mt-3">
                <label htmlFor="">Student ID</label>
                <input
                  disabled={disabled}
                  value={user.full_name}
                  type="text"
                  placeholder="Enter Student ID"
                  className="form-control shadow-none" />
              </div>
            ) : type === "Batch" ? (
              <>
                <div className="col-lg-4 mt-3">
                  <label htmlFor="">Batch</label>
                  <select className="form-control shadow-none">
                    <option value="">Select Batch</option>
                    <option value="Batch 11">Batch 11</option>
                    <option value="Batch 12">Batch 12</option>
                    <option value="Batch 15">Batch 15</option>
                  </select>
                </div>
                <div className="col-lg-4 mt-3">
                  <label htmlFor="">Session</label>
                  <select className="form-control shadow-none">
                    <option value="">Select Session</option>
                    <option value="Weekend">Weekend</option>
                    <option value="Weekday">Weekday</option>
                    <option value="Custom">Custom</option>
                  </select>
                </div>
                <div className="col-lg-4 mt-3">
                  <label htmlFor="Course">Course</label>
                  <select className="form-control shadow-none">
                    <option value="">Select Course</option>
                    <option value="Full Stack Development">Full Stack Development</option>
                    <option value="Data Analytics">Data Analytics</option>
                    <option value="Data Science">Data Science</option>
                    <option value="Cyber Security">Cyber Security</option>
                  </select>
                </div>
              </>
            ) : null}

            {phases.length ? (
              <>
                <h5 className="mt-3">Phases</h5>
                {phases.map((phase) => (
                  <div key={phase.id} className="col-lg-4 mt-3">
                    <div className="shadow-sm p-2 bg-white">
                      <h3>{phase.name}</h3>
                      <p>{phase.duration}</p>
                      <div>
                      <div className="form-group mr-1">
                          <label htmlFor="">Submission trial</label>
                          <input 
                            type="text" 
                            onChange={(e)=>handlePhaseOnChange(e,phase,"submission_trials")}
                            className="form-control" />
                        </div>
                        <div className="form-group mr-1">
                          <label htmlFor="">Start Date</label>
                          <input 
                            type="datetime-local" 
                            onChange={(e)=>handlePhaseOnChange(e,phase,"start_date")}
                            className="form-control" />
                        </div>
                        <div className="form-group mt-2">
                          <label htmlFor="">End Date</label>
                          <input 
                            type="datetime-local" 
                            onChange={(e)=>handlePhaseOnChange(e,phase,"end_date")}
                            className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : null}
          </div>
          <div className="text-center mt-3">
            <button 
              disabled={loading ? "disabled" : null}
              className="btn btn-outline-primary">
            {loading ? <ClipLoader color="#0a6bfb" /> : "Assign Project"}
             
              </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AssignProject;
