import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { FiSearch } from "react-icons/fi";
import PaymentService from "./PaymentService";
import ClipLoader from "react-spinners/ClipLoader";


export const PaymentForm = () => {
  let location = useLocation();
 
  let initial_state = {
    user_id: "",
    student_id: "",
    paid: "",
    full_name:"",
    date_paid: "",
    method: "",
    bank: ""
  }

  let [locate, setLocate] = useState("");

  let [loading, setLoading] = useState(false);
  let [loading_search,setLoadingSearch] = useState(false);
  let [data, setData] = useState(initial_state);

  let handleOnchange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  let handleFormatDate = () =>{
    let d = new Date().toLocaleDateString();
    let arr = d.split('/');
    
    let year= arr[2];
    let month = arr[0];
    let day = arr[1];

    return `${year}-${month}-${day}`
  }

  let handleFindStudent = () => {
    setLoadingSearch(true);

    PaymentService.getStudentById(data.student_id)
    .then(res=>{

      setLoadingSearch(false);

      if(res.code === 404){
        setData({...initial_state,student_id:data.student_id});
      }
      if(res.id){
        let new_data = {
          ...data,
          user_id:res.id,
          full_name:res.full_name
        };
        setData(new_data);
      }
    })
    .catch(()=>{
      setLoadingSearch(false);
      setData({...data});
    })

  }

  let handleCreatePayment = (e) => {
    e.preventDefault();

    setLoading(true);

    PaymentService.create(data)
      .then((_) => {


        if (_.type === "SUCCESS") {

          setLoading(false);
          setData(initial_state);

          toast(_.msg, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

        }

      })

  }

  useEffect(() => {
    setLocate(location.pathname);
  }, [location]);

  return (
    <>
      <form className="form course_form" onSubmit={handleCreatePayment}>
        <div className="row">
          <div className="row">
            <div className="col-lg-11">
              <label htmlFor="">Student ID</label>
              <input
                type="text"
                name="student_id"
                value={data.student_id}
                onChange={handleOnchange}
                placeholder="Enter Student ID"
                className="form-control shadow-none" />
            </div>
            <div className="col-lg-1 mt-4 d-flex flex-row-reverse" style={{"paddingRight":0}}>
              <button
                type="button"
                style={{height: '33px',overflow: 'hidden',padding: '6px 12px'}}
                disabled={loading_search ? "disabled" : null}
                onClick={handleFindStudent}
                className="btn btn-outline-primary">
                {loading_search ? <ClipLoader color="#0a6bfb" /> : <FiSearch/>}
              </button>
            </div>
          </div>
          <div className="col-lg-12">
            <label htmlFor="">Student Name</label>
            <input
              type="text"
              name="full_name"
              value={data.full_name}
              onChange={handleOnchange}
              disabled
              placeholder="Student Name"
              className="form-control shadow-none" />
          </div>
          <div className="col-lg-12">
            <label htmlFor="">Amount</label>
            <input
              type="text"
              name="paid"
              value={data.paid}
              pattern="^\d*(,\d*)*$"
              onChange={handleOnchange}
              placeholder="Enter Amount"
              className="form-control shadow-none" />
          </div>
          <div className="col-lg-12">
            <label htmlFor="">Payment Method</label>
            <select
              name="method"
              value={data.method}
              onChange={handleOnchange}
              className="form-control shadow-none">
              <option value="">Select Payment Method</option>
              <option value="BANK_TRANSFER">BANK TRANSFER</option>
              <option value="POS">POS</option>
              <option value="CASH">CASH</option>
            </select>
          </div>
          <div className="col-lg-12">
            <label htmlFor="">Bank</label>
            <input
              type="text"
              name="bank"
              value={data.bank}
              onChange={handleOnchange}
              placeholder="Enter Bank"
              className="form-control shadow-none" />
          </div>

          <div className="col-lg-12">
            <label htmlFor="">Payment Date</label>
            <input
              type="date"
              name="date_paid"
              max={handleFormatDate()}
              value={data.date_paid}
              onChange={handleOnchange}
              className="form-control shadow-none" />
          </div>

        </div>
        <div className="course_form_btn">
          {locate.includes("update_payment") ? (
            <button className="btn btn-outline-primary">Update Payment</button>
          ) : (
            <button
              disabled={loading ? "disabled" : null}
              className="btn btn-outline-primary">
              {loading ? <ClipLoader color="#0a6bfb" /> : " Create Payment"}
            </button>
          )}
        </div>
      </form>
    </>
  );
};
