import { useState } from "react";
import { Loading } from "../../components/loading/Loading";
import { Dropdown } from "react-bootstrap";
import { BsChevronDown } from "react-icons/bs";
import { Link } from "react-router-dom";

export const BranchTable = ({ branches }) => {
  const [loading] = useState(false);
  const [errMsg] = useState("");
  return (
    <div className="table_container px-4">
      <table className="table table-bordered">
        <thead className="table-secondary">
          <tr>
            <th>Name</th>
            <th>Country</th>
            <th>State</th>
            <th>District</th>
            <th>Address</th>
            <th>Students</th>
            <th>Accepted</th>
            <th>Rejected</th>
            <th>Trainers</th>
            <th>Staff</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {branches.length ? (
            branches.map((branch) => (
              <tr key={branch.id}>
                <td>{branch.name}</td>
                <td>{branch.country}</td>
                <td>{branch.state}</td>
                <td>{branch.district}</td>
                <td>{branch.address}</td>
                <td>{branch.students}</td>
                <td>{branch.accepted}</td>
                <td>{branch.rejected}</td>
                <td>{branch.trainers}</td>
                <td>{branch.staffs}</td>
                <td className="my_dropdown">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <BsChevronDown />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Link className="dropdown-item" to={`/branches/update_branch/${branch.id}`}>
                        Update Branch
                      </Link>
                      <Link className="dropdown-item" to={`/branches/delete/${branch.id}`}>
                        Delete Branch
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))
          ) : loading === true ? (
            <tr className="table_loading">
              <td colSpan={11}>
                <Loading />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={11}>{errMsg}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
