import { createContext, useState } from "react";

export const AssessmenContext = createContext();

function AssessmentProvider({ children }) {
  const [assessments, setAssessments] = useState([
    {
      id: "1",
      name: "Apple Website",
      difficulty_level:"Beginner",
      category: "HTML & CSS",
      duration: "1 week",
      type: "Project",
      description: "Design the Apple Website",
      attachment: true,
      participants: 12,
      phases: [
        { id: "1", name: "Phase One", has_attachment: false, duration: "3 Days", tasks: [{id:1,title:"Home Page"},{id:2, title:"About Page"}] },
        { id: "2", name: "Phase Two", has_attachment: false, duration: "3 Days", tasks: [{id:1,title:"Services Page"},{id:2,title:"Contact Page"}] },
      ],
    },
  ]);
  return <AssessmenContext.Provider value={{ assessments, setAssessments }}>{children}</AssessmenContext.Provider>;
}

export default AssessmentProvider;
