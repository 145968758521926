import { createContext, useState } from "react";

export const ModuleContext = createContext();

function ModuleProvider({ children }) {
  const initial_data = [
    {
      id: "1",
      name: "HTML & CSS",
      contents: [
        {
          id: "1",
          title: "HTML Tags",
          day: "Day 1",
          topics: [
            {
              id: 1,
              topic: "H1 - H6"
            }]
        }
      ]
    }
  ];
  const [modules, setModules] = useState(initial_data);
 
  return <ModuleContext.Provider value={{ modules, setModules }}>{children}</ModuleContext.Provider>;
}

export default ModuleProvider;
