import { BsCloudUpload } from "react-icons/bs";
import { Navigation } from "../../components/navigation/Navigation";
import { useState,useEffect } from 'react'
import TrainerService from "./TrainerService";
import BranchService from "./BranchService";
import BeatLoader from "react-spinners/BeatLoader";

function CreateTrainer() {

  let init_state = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    gender: "",
    branch_id: "",
    addr: "",
    primary_phone: "",
    phones: []
  }
  let [file,setFile] = useState();
  let [disabled,setDisabled] = useState(null);
  let [data, setData] = useState(init_state)
  let [branches,setBranch] = useState([]);


  let handleOnchange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  let handleCreateTrainer = () => {
   
    setDisabled(true);

    TrainerService.createTrainer(data)
    .then((res) => {
        if (res.type === 'SUCCESS') {
          TrainerService.uploadImage(res.data.id,file)
          .then((res) =>{
            if(res.type === 'SUCCESS'){
              setData(init_state);
              setDisabled(null);
              setFile("");
            }
          });
        }
    });

  }

  useEffect(()=>{

    BranchService
    .getBranches()
    .then(e =>{
      
      let batches = e.map(d=>{
        return {
            id:d.id,
            name:d.name
        }
      })

      setBranch(batches)
    })

  },[])

  return (
    <>
      <Navigation title="Create Trainer" />
      <div className="container">
        <form className="form course_form">
          <div className="row">
            <div className="col-lg-6">
              <label htmlFor="">First Name</label>
              <input
                type="text"
                placeholder="Enter First name"
                className="form-control shadow-none"
                name="first_name"
                value={data.first_name}
                onChange={handleOnchange} />
            </div>
            <div className="col-lg-6">
              <label htmlFor="">Last Name</label>
              <input
                type="text"
                placeholder="Enter Last Name"
                className="form-control shadow-none"
                name="last_name"
                value={data.last_name}
                onChange={handleOnchange} />
            </div>
            <div className="col-lg-6">
              <label htmlFor="">Email Address</label>
              <input
                type="text"
                placeholder="Enter Email Address"
                className="form-control shadow-none"
                name="email"
                value={data.email}
                onChange={handleOnchange} />
            </div>
            <div className="col-lg-6">
              <label htmlFor="">Phone Number</label>
              <input
                type="text"
                placeholder="Enter Phone Number"
                className="form-control shadow-none"
                name="phone"
                value={data.phone}
                onChange={handleOnchange} />
            </div>
            <div className="col-lg-6">
              <label htmlFor="">Gender</label>
              <select
                className="form-control shadow-none"
                name="gender"
                value={data.gender}
                onChange={handleOnchange}>
                <option value="">--Select Gender--</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div className="col-lg-6">
              <label htmlFor="">Select Branch</label>
              <select
                className="form-control shadow-none"
                name="branch_id"
                value={data.branch_id}
                onChange={handleOnchange}>
                <option value="">--Select Branch--</option>
                {
                  branches.map(e => <option key={e.id} value={e.id}>{e.name}</option> )
                }
              </select>
            </div>
            <div className="col-lg-6">
              <label htmlFor="">Address</label>
              <textarea
                className="form-control shadow-none"
                placeholder="Enter Address"
                name="addr"
                value={data.addr}
                onChange={handleOnchange}></textarea>
            </div>
            <div className="col-lg-6">
              <label htmlFor="">Upload Profile Photo</label>
              <div className="course_form_upload trainer_form_upload">
                <label htmlFor="trainer_profile_image">
                  <BsCloudUpload />
                  <span>{file ? file.name : "Upload Profile Image"}</span>
                </label>
                <input 
                  type="file"
                  onChange={ e=> setFile(e.target.files[0])} 
                  id="trainer_profile_image" 
                  className="d-none" />
              </div>
            </div>
          </div>
          <div className="text-center mt-4">
            <button 
              type="button"
              className="btn btn-outline-primary" 
              disabled={ disabled ? "disabled" : null }
              onClick={handleCreateTrainer}>
                {disabled ? <BeatLoader color="#0a6bfb" /> : "Create Trainer"}
              </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateTrainer;
