import React from "react";
import ReactDOM from "react-dom/client";
import {ReactTypeExtender} from "react-type-extender";
import App from "./App";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/root.css";
import "./styles/sidebar.css";
import "./styles/navigation.css";
import "./styles/card.css";
import "./styles/content.css";
import "./styles/table.css";
import "./styles/Form.css";
import "./styles/media-query.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ReactTypeExtender/>
    <App />
    <ToastContainer />
  </React.StrictMode>
);
