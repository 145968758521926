import axios from 'axios';
import config from "../config"

class Service{

    getModules(){ 
        let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/courses/module?type=all");
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    createModule(payload){ 
      let data = {...payload}

      let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/courses/module");
      
      delete data.id;
      delete data.topics;

      if(data.is_new ==="true"){
         data.is_new = true;
       }else{
         data.is_new = false;
       }

       if(data.objectives){
         data.objectives = data.objectives.split(",");
       }

        return new Promise((resolve,reject) => {
         axios.post(url,data)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    createCourseContent(data){ 
      let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/courses/content");
     
        return new Promise((resolve,reject) => {
         axios.post(url,data)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    createCourseContentTopic(data){ 
      let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/courses/content/topic");
     
        return new Promise((resolve,reject) => {
         axios.post(url,data)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

   deleteCourseModule(id){
      let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/courses/module/:id/permanent").bind(id);
     
      return new Promise((resolve,reject) => {
       axios.delete(url)
       .then(res => {
          resolve(res.data);
       })
       .catch(err => reject(err.message));
     })
   }

   deleteCourseContent(id){
      let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/courses/content/:id").bind(id);
     
      return new Promise((resolve,reject) => {
       axios.delete(url)
       .then(res => {
          resolve(res.data);
       })
       .catch(err => reject(err.message));
     })
   }

   deleteCourseTopic(id){
      let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/courses/content/topic/:id").bind(id);
     
      return new Promise((resolve,reject) => {
       axios.delete(url)
       .then(res => {
          resolve(res.data);
       })
       .catch(err => reject(err.message));
     })
   }

}
const service = new Service();

export default service;