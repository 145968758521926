import React from "react";
import { BsBell, BsSearch } from "react-icons/bs";

export const Navigation = ({ title }) => {
  return (
    <div className="navigation">
      <div className="d-flex justify-content-between align-items-center">
        <h4>{title}</h4>
        <div className="navigation_subheader">
          <BsSearch />
          <BsBell />
        </div>
      </div>
    </div>
  );
};
