import axios from 'axios';
import config from "../config"

class Service{

    getTrainers(){
        let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/trainers");
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    getTrainer(user_id){
        let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/trainers/:user_id/id".bind(user_id));
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    createTrainer(data){
        data.phones = data.phone.split(',');
        data.primary_phone = data.phones[0];
     
        let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/trainers");
        return new Promise((resolve,reject) => {
         axios.post(url,data)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    uploadImage(user_id,data){

        let formData = new FormData();
        let url = ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/trainers/:user_id/resources").bind(user_id);
        
        formData.append("avatar", data);
  
        return fetch(url, {
            method: "PUT",
            body: formData,
        }).then((res) => res.json());
       
    };

}

export default new Service();