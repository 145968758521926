import { Accordion } from "react-bootstrap";
import { BsPencil, BsTrash } from "react-icons/bs";

function ModuleContent({ modules }) {
  return (
    <div className="c__modules_breakdown_display">
      <div className="c_modules_header">
        <h4 className=" p-2">Module & Content Breakdown</h4>
      </div>
      <div className="c_modules_breakdown mt-3">
        <Accordion>
          {modules.length ? (
            // modules.map((content_item) => (
            //   <Accordion.Item eventKey={content_item.id} key={content_item.id}>
            //     <Accordion.Header>{content_item.name}</Accordion.Header>
            //     <Accordion.Body>
            //       {content_item?.contents.map((c) => (
            //         <div key={c.id}>
            //           <h5 className="shadow-sm p-2 d-flex justify-content-between align-items-center">
            //             <span style={{textTransform:"uppercase"}}>
            //               {c.title}
            //             </span>
            //             <div className="d-flex c_modules_breakdown_icons">
            //               <button className="btn btn-outline-primary">
            //                 <BsPencil />
            //               </button>
            //               <button className="btn btn-outline-danger">
            //                 <BsTrash />
            //               </button>
            //             </div>
            //           </h5>
            //           <ul className="c_modules_breakdown_list">
            //             {c.topics.map((item) => (
            //               <li key={item.id}>{item.topic}</li>
            //             ))}
            //           </ul>
            //         </div>
            //       ))}
            //     </Accordion.Body>
            //   </Accordion.Item>
            // ))
            <b>s</b>
          ) : (
            <h4>No Content</h4>
          )}
        </Accordion>
      </div>
    </div>
  );
}

export default ModuleContent;
