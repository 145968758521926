import { BsPeople, BsPersonExclamation, BsPersonCheck, BsPersonDown, BsPlus, BsSearch } from "react-icons/bs";
import { IoSchoolOutline } from "react-icons/io5";
import { Card1 } from "../../components/cards/Card";
import "./style.css";
import StudentTable from "./StudentTable";
import { useStudentContext } from "../../hooks/useStudentContext";
import { useEffect } from "react";
import { Navigation } from "../../components/navigation/Navigation";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

function Students() {
  const { students, errMsg, getAllStudents, getInterests } = useStudentContext();

  useEffect(() => {
    getAllStudents();
  }, []);

  return (
    <>
      <Navigation title="Students" />
      <div className="students_header">
        <Card1 title="Total" content={"1000"} icon={<BsPeople />} setClick={getAllStudents} />
        <Card1 title="Interests" content="100" icon={<BsPersonDown />} setClick={getInterests} />
        <Card1 title="Completed" content="200" icon={<IoSchoolOutline />} />
        <Card1 title="InActive Students" content="20" icon={<BsPersonExclamation />} />
        <Card1 title="Active Students" content="150" icon={<BsPersonCheck />} />
      </div>
      <div className="students_subheader">
        <Dropdown>
          <Dropdown.Toggle variant="primary">
            New <BsPlus />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Link className="dropdown-item" to="/students/create_new">
              New Intake
            </Link>
            <Link className="dropdown-item" to="/students/create_existing">
              Existing Student
            </Link>
          </Dropdown.Menu>
        </Dropdown>
        <div className="students_subheader_input">
          <input type="text" placeholder="Search" />
          <button>
            <BsSearch />
          </button>
        </div>
      </div>
      <div>
        <StudentTable errMsg={errMsg} students={students} />
      </div>
    </>
  );
}

export default Students;
