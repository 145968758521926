import { useState } from "react";
import BatchService from "./BatchService";

function CreateSessions({ batches, setBatches }) {
  const [session, setSession] = useState("");
  const [startDate, setStartDate] = useState("");
  const [batchId, setBatchId] = useState("");
  const [err, setErr] = useState(false);

  const handleCreateSession = (e) => {
    e.preventDefault();
    if (session === "" || startDate === "") {
      setErr(true);
      return;
    }
    let data = {
      "value": session,
      "start_date": startDate
    }

     BatchService
      .createSession(batchId, data)
      .then(res => {

        // const new_session = {
        //   id: res.data.id,
        //   session_name: session,
        //   start_date: startDate,
        // };

        // const UpdateBatch = batches.map((batch) => {
        //   if (batch.id === batchId) {
        //     batch.sessions = [...batch.sessions, new_session];
        //     return batch;
        //   }
        //   return batch;
        // });

        // setBatches((prev) => (prev = UpdateBatch));
        // setSession("");
        // setStartDate("");
        // setBatchId("");
        // setErr(false);

      })


  };

  return (
    <div className="c__modules pb-2">
      <div className="batches_header">
        <h4 className=" p-2">Create Session</h4>
        <form className="mt-3 p-2" onSubmit={handleCreateSession}>
          <div className="form-group mb-3">
            <label htmlFor="">Batch</label>
            <select className="form-control shadow-none" value={batchId} onChange={(e) => setBatchId((prev) => (prev = e.target.value))}>
              <option value="">Select Batch</option>
              {batches.map((batch) => (
                <option value={batch.id} key={batch.id}>
                  {batch.name}
                </option>
              ))}
            </select>
            {err && batchId === "" ? <span>Batch Required</span> : null}
          </div>
          <div className="form-group mb-3">
            <label htmlFor="">Session</label>
            <select value={session} onChange={(e) => setSession(e.target.value)} className="form-control shadow-none">
              <option value="">Select Session</option>
              <option value="weekday">Weekday</option>
              <option value="weekend">Weekend</option>
              <option value="self pace">Selfpace</option>
              <option value="custom">Custom</option>
            </select>
            {err && session === "" ? <span>Session Required</span> : null}
          </div>
          <div className="form-group mb-3">
            <label htmlFor="">Start Date</label>
            <input type="date" placeholder="Enter Start Date" className="form-control shadow-none" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            {err && startDate === "" ? <span>Start Date Required</span> : null}
          </div>
          <div className="form-group">
            <button className="btn btn-outline-secondary">Create Session</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateSessions;
