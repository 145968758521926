import { Navigation } from "../../components/navigation/Navigation";
import { BranchForm } from "./BranchForm";

function UpdateBranch() {
  return (
    <>
      <Navigation title="Update Branch" />
      <BranchForm />
    </>
  );
}

export default UpdateBranch;
