import React, { useEffect, useState } from "react";
import { Navigation } from "../../components/navigation/Navigation";
import { useAssessmentContext } from "../../hooks/useAssessmentContext";
import AssessmentService from "./AssessmentService";
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from 'react-toastify';

function CreateAssessment() {

  const { setAssessments } = useAssessmentContext();
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [duration, setDuration] = useState("");
  const [difficulty_level, setDifficulty] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [err, setErr] = useState(false);
  const [modules,setModule] = useState([]);
  let [loading, setLoading] = useState(false);

  const handleCreateAssessment = (e) => {
    e.preventDefault();
    if (name === "" || 
        description === "" || 
        category === "" || 
        difficulty_level === "" || 
        type === "" || 
        duration === "") {
      setErr(true);
      return;
    }
    setLoading(true);

    const new_asssessment = {
      id: "",
      name,
      category,
      duration,
      difficulty_level,
      attachment: false,
      type: type,
      description,
      participants: 0,
      phases: [],
    };

    AssessmentService
    .createProjectPool(new_asssessment)
    .then(res=>{
      setLoading(false);
      if(res.type === 'SUCCESS'){

        new_asssessment["id"] = res.data.id;
        setAssessments((prev) => [...prev, new_asssessment]);
        setName("");
        setCategory("");
        setDifficulty("");
        setDuration("");
        setType("");
        setDescription("");

        toast(res.msg, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    })


  };

  useEffect(()=>{

    AssessmentService
    .getModules()
    .then(res=>{
      setModule(res);
    })

  },[]);

  return (
    <>
      <Navigation title="Create Assessment" />
      <div className="container">
        <form className="form project_form" onSubmit={handleCreateAssessment}>
          <div className="row">
            <div className="col-lg-12 mt-3">
              <label htmlFor="">Project Name</label>
              <input type="text" placeholder="Enter Name" className="form-control shadow-none" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="col-lg-12 mt-3">
              <label htmlFor="">Category</label>

              <select className="form-control shadow-none" value={category} onChange={(e) => setCategory(e.target.value)}>
                <option value="">Select Category</option>
                {
                  modules.map(m=> <option key={m.id} value={m.id}>{m.name}</option>)
                }
              </select>

            </div>
            <div className="col-lg-12 mt-3">
              <label htmlFor="">Duration</label>
              <input type="text" placeholder="Enter Duration" className="form-control shadow-none" value={duration} onChange={(e) => setDuration(e.target.value)} />
            </div>
            <div className="col-lg-12 mt-3">
              <label htmlFor="">Type</label>
              <select className="form-control shadow-none" value={type} onChange={(e) => setType(e.target.value)}>
                <option value="">Select</option>
                <option value="Classwork">Classwork</option>
                <option value="Assignment">Assignment</option>
                <option value="Project">Project</option>
                <option value="Entry Project">Entry Project</option>
              </select>
            </div>
            <div className="col-lg-12 mt-3">
              <label htmlFor="">Difficulty Level</label>
              <select className="form-control shadow-none" value={difficulty_level} onChange={(e) => setDifficulty(e.target.value)}>
                <option value="">Select</option>
                <option value="beginner">BEGINNER</option>
                <option value="intermeidate">INTERMEDIATE</option>
                <option value="advance">ADVANCE</option>
                <option value="professional">PROFESSIONAL</option>
              </select>
            </div>
            <div className="col-lg-12 mt-3">
              <label htmlFor="">Description</label>
              <textarea
                className="form-control shadow-none"
                placeholder="Enter Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="text-center mt-3">
            <button 
              disabled={loading ? "disabled" : null}
              className="btn btn-outline-primary">
                 {loading ? <BeatLoader color="#0a6bfb" /> : "Create Project"}
              </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateAssessment;
