import axios from 'axios';
import config from "../config"

class Service {

   getCourses() {
      let url = (config.MODE === "DEV" ? config.DEV : config.PROD).concat("/courses/all")
      return new Promise((resolve, reject) => {
         axios.get(url)
            .then(res => {
               resolve(res.data);
            })
            .catch(err => {
               console.error(err);
               reject(err.message)
            });
      })
   }

   create(payload) {
      let url = (config.MODE === "DEV" ? config.DEV : config.PROD).concat("/courses")
      return new Promise((resolve, reject) => {
         axios.post(url, payload)
            .then(res => {
               resolve(res.data);
            })
            .catch(err => {
               reject(err.message)
            });
      })
   }

   uploadImage(course_id, icon,bg) {

      let formData = new FormData();
      let url = ((config.MODE === "DEV") ? config.DEV : config.PROD).concat("/courses/:course_id/resources").bind(course_id);
      let has_data = false;

      if(icon){
         formData.append("icon", icon);
         has_data = true;
      }
      if(bg){
         formData.append("bg", bg);
         has_data = true;
      }

      if (has_data){
         return fetch(url, {
            method: "PUT",
            body: formData,
         }).then((res) => res.json());
      }
      return new Promise((res) =>res({msg:"No image sent",type:"SUCCESS",code:200}));
       

   }

}
const service = new Service();
export default service;