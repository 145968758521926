import { BsPlus } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useStudentContext } from "../../hooks/useStudentContext";
import { Link } from "react-router-dom";
import { Navigation } from "../../components/navigation/Navigation";
import CourseService from "./CourseService";
import CourseTable from "./CourseTable";
import "./style.css";

function Courses() {
  
  const { errMsg, loading,setLoading } = useStudentContext();
  const [courses,setCourse] = useState([{ id: 1, name: "Full Stack Web development NODE JS", monthly_fee: 56900, total_fee: 512100, duration: "9 months", total_enrolled: 120 }]);

  useEffect(()=>{

    setLoading(false)

    CourseService.getCourses()
    .then(res=>{
      setLoading(false)
      setCourse(res)
    })

  },[])

  return (
    <>
      <Navigation title="Courses" />
      <div className="students_subheader">
        <Link to="/courses/create" className="btn btn-outline-primary students_subheader_btn1">
          <span>New</span> <BsPlus />
        </Link>
        <div className="students_subheader_input">
          <input type="text" placeholder="Search" />
        </div>
      </div>
      <CourseTable courses={courses} errMsg={errMsg} loading={loading} />
    </>
  );
}

export default Courses;
