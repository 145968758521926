import { Outlet } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";

const MainLayout = () => {
  return (
    <div className="d-flex">
      <Sidebar />
      <div className="layout-width">
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
