import ReactPaginate from "react-paginate";
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';

const Paginate = ({paginate,handlePageClick}) => {


  if (paginate) {
 
    let to = paginate?.page * paginate?.limit || 1;
    let from = (to - paginate?.limit || 1) < 0 ? 1 : (to - paginate?.limit || 1);


    return <div className="d-flex justify-content-end align-items-center">
      <ul className="paginate-total">
        <li>Total: {from}/{to} of {paginate?.total} </li>
      </ul>
      <ReactPaginate
        previousLabel={<IoChevronBackOutline />}
        nextLabel={<IoChevronForwardOutline />}
        breakLabel={"..."}
        pageCount={paginate?.pages}
        marginPagesDisplayed={3}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={"pagination justify-content-end"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-link"}
        nextClassName={"page-link"}
        nextLinkClassName={"page-item"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
      />
    </div>
  }
}
export default Paginate;