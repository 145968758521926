import axios from 'axios';
import config from "../config"

class Service{

    getModules(){ 
        let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/courses/module?type=all");
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    getTrainer(){ 
      let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/trainers?paginate=false&fields=[id,first_name,last_name]");
      return new Promise((resolve,reject) => {
       axios.get(url)
       .then(res => {
          resolve(res.data);
       })
       .catch(err => reject(err.message));
     })
  }

    getStudent(id){
        let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/students/:id").bind(id);
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    getProjectPool(){ 
        let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/project-pool?limit=50&page=1&sort=desc");
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    getProjectPhase(id){ 
        let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/project-pool/:id").bind(id);
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    getAssessment(trainer_id){ 
    
      let url;
      if(trainer_id){
         url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/assessments").query({trainer:trainer_id});
      }
      else{
         url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/assessments?limit=50");

      }
      
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    getStudentAssessment(user_id,assessment_id){ 
    
      let url = ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/students/:user_id/assessments/:assessment_id").bind(user_id,assessment_id);
     
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    getAssessmentParticipant(assessment_id){ 
    
      let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/assessments/:assessment_id/participant").bind(assessment_id);
   
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }


    assignAssessment(payload){ 
    
      let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/assessments?limit=50");
      

        return new Promise((resolve,reject) => {
         axios.post(url,payload)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }
    
    createProjectPool(payload){ 
    
      let data = {
        "title":payload.name,
        "module_id":payload.category,
        "category":payload.type,
        "descp":payload.description,
        "duration":payload.duration,
        "difficulty_level":payload.difficulty_level
      }

      let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/project-pool");
      

        return new Promise((resolve,reject) => {
         axios.post(url,data)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }


    createProjectPhase(payload){ 
    
        let data = {
          "pool_id":payload.pool_id,
          "title":payload.name,
          "descp":payload.descp,
          "duration":payload.duration
        }
  
        let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/project-pool/phase");
        
         return new Promise((resolve,reject) => {
           axios.post(url,data)
           .then(res => {
              resolve(res.data);
           })
           .catch(err => reject(err.message));
         });

      }

      uploadResource(phase_id,resource){ 
  

         let formData = new FormData();
         let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/project-pool/phase/:phase_id/resources").bind(phase_id);
         let has_data = false;
   
         if(resource){
            formData.append("resource", resource);
            has_data = true;
         }
   
         if (has_data){
            return fetch(url, {
               method: "PUT",
               body: formData,
            }).then((res) => res.json());
         }
         return new Promise((res) =>res({msg:"No image sent",type:"SUCCESS",code:200}));

   
       }
 



    createCourseContent(data){ 
      let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/courses/content");
     
        return new Promise((resolve,reject) => {
         axios.post(url,data)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    createCourseContentTopic(data){ 
      let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/courses/content/topic");
     
        return new Promise((resolve,reject) => {
         axios.post(url,data)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

   deleteStudentFromAssessment(user_id){
      return new Promise((resolve,reject) => {
         resolve({id:user_id});
         // axios.delete(url)
         // .then(res => {
         //    resolve(res.data);
         // })
         // .catch(err => reject(err.message));
       }) 
   }

   deleteCourseModule(id){
      let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/courses/module/:id/permanent").bind(id);
     
      return new Promise((resolve,reject) => {
       axios.delete(url)
       .then(res => {
          resolve(res.data);
       })
       .catch(err => reject(err.message));
     })
   }

   deleteCourseContent(id){
      let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/courses/content/:id").bind(id);
     
      return new Promise((resolve,reject) => {
       axios.delete(url)
       .then(res => {
          resolve(res.data);
       })
       .catch(err => reject(err.message));
     })
   }

   deleteCourseTopic(id){
      let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/courses/content/topic/:id").bind(id);
     
      return new Promise((resolve,reject) => {
       axios.delete(url)
       .then(res => {
          resolve(res.data);
       })
       .catch(err => reject(err.message));
     })
   }

}
const service = new Service();

export default service;