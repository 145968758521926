import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Data Providers
import { StudentProvider } from "./contexts/StudentContext";

// Layouts
import MainLayout from "./layouts/MainLayout";

// Pages
import Students from "./pages/students/Students";
import Dashboard from "./pages/dashboard/Dashboard";
import Courses from "./pages/courses/Courses";
import CreateCourse from "./pages/courses/CreateCourse";
import ManageCourseModules from "./pages/courses/ManageCourseModules";
import CreateNewStudent from "./pages/students/CreateNewStudent";
import CreateExistingStudent from "./pages/students/CreateExistingStudent";
import UpdateStudent from "./pages/students/UpdateStudent";
import ManageTasks from "./pages/assessments/ManageTasks";
import AssignedProjects from "./pages/assessments/AssignedProjects";
import AssignProject from "./pages/assessments/AssignProject";
import Assessments from "./pages/assessments/Assessments";
import CreateAssessment from "./pages/assessments/CreateAssessments";
import AssessmentProgress from "./pages/assessments/AssessmentProgress";
import Modules from "./pages/modules/Modules";
import ModuleProvider from "./contexts/ModulesContext";
import AssessmentProvider from "./contexts/AssessmentContext";
import Batches from "./pages/batches/Batches";
import Trainers from "./pages/trainers/Trainers";
import CreateTrainer from "./pages/trainers/CreateTrainer";
import Branches from "./pages/branch/Branches";
import CreateBranch from "./pages/branch/CreateBranch";
import UpdateBranch from "./pages/branch/UpdateBranch";
import Payments from "./pages/payments/Payments";
import CreatePayment from "./pages/payments/CreatePayment";
import UpdatePayment from "./pages/payments/UpdatePayment";
import Attendance from "./pages/attendance/Attendance";
import Notifications from "./pages/notifications/Notifications";
import CreateNotification from "./pages/notifications/CreateNotification";

function App() {
  return (
    <StudentProvider>
      <ModuleProvider>
        <AssessmentProvider>
          <Router>
            <Routes>
              <Route element={<MainLayout />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/students" element={<Students />} />
                <Route path="/students/create_new" element={<CreateNewStudent />} />
                <Route path="/students/create_existing" element={<CreateExistingStudent />} />
                <Route path="/students/update_student" element={<UpdateStudent />} />
                <Route path="/courses" element={<Courses />} />
                <Route path="/courses/create" element={<CreateCourse />} />
                <Route path="/courses/:id/manage_modules" element={<ManageCourseModules />} />
                <Route path="/assessments" element={<Assessments />} />
                <Route path="/assessments/create_new" element={<CreateAssessment />} />
                <Route path="/assessments/:id/tasks" element={<ManageTasks />} />
                <Route path="/assessments/progress/:assessment_id" element={<AssessmentProgress />} />
                <Route path="/projects/assigned" element={<AssignedProjects />} />
                <Route path="/projects/assign" element={<AssignProject />} />
                <Route path="/modules" element={<Modules />} />
                <Route path="/batches" element={<Batches />} />
                <Route path="/trainers" element={<Trainers />} />
                <Route path="/trainers/create_new" element={<CreateTrainer />} />
                <Route path="/branches" element={<Branches />} />
                <Route path="/branches/create" element={<CreateBranch />} />
                <Route path="/branches/update_branch/:id" element={<UpdateBranch />} />
                <Route path="/payments" element={<Payments />} />
                <Route path="/payments/create" element={<CreatePayment />} />
                <Route path="/payments/update_payment/:id" element={<UpdatePayment />} />
                <Route path="/attendance" element={<Attendance />} />
                <Route path="/notifications" element={<Notifications />} />
                <Route path="/notifications/create_new" element={<CreateNotification />} />
              </Route>
            </Routes>
          </Router>
        </AssessmentProvider>
      </ModuleProvider>
    </StudentProvider>
  );
}

export default App;
