import axios from 'axios';
import config from "../config"

class Service{

    getBranches(){
        let url =  ((config.MODE==="DEV") ? config.DEV : config.PROD).concat("/branch");
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

}

export default new Service();