import { useParams } from "react-router-dom";
import { Navigation } from "../../components/navigation/Navigation";
import { useAssessmentContext } from "../../hooks/useAssessmentContext";
import { BsFileEarmarkArrowDown, BsPlus, BsThreeDotsVertical, BsXLg } from "react-icons/bs";
import { Accordion, Dropdown } from "react-bootstrap";
import { useEffect, useState } from "react";

import ClipLoader from "react-spinners/ClipLoader";

import AssessmentService from './AssessmentService';

function AssessmentProgress() {
  const { assessment_id } = useParams();
  const { assessments } = useAssessmentContext();
  // console.log(assessments)
  const currentAssessment = assessments.find((asessment) => asessment.id === "1");
  const [students, setStudent] = useState([])
  const [phases,setPhases] = useState([]);
  const [current_student,setCurrentStudent] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const[active,setActive] = useState(false);


  const removeStudent = (id) => {
    AssessmentService
      .deleteStudentFromAssessment(id)
      .then(res => {
        console.log(res)
      })
  }

  const handleGetAssessment = (student) => {
   
    setLoading(true);
    setShow(true);

    AssessmentService
    .getStudentAssessment(student?.user_id,assessment_id)
    .then(res => {

      setPhases(res.phases);
      setLoading(false);
      setShow(true);
      setActive(true);
      setCurrentStudent(student?.full_name);

    });

  }

  const downloadFile = (phase)=>{

    if(phase.submissions.length){
      window.location.href  = phase.submissions[0].submission_link
    }
    // if(file) window.location.href = file.submission_link
  }

  useEffect(() => {

    AssessmentService
    .getAssessmentParticipant(assessment_id)
    .then(res => {
      if (res.data) {
        setStudent(res.data)
      }
    });

  }, []);

  return (
    <>
      <Navigation title="View Progress" />
      <div className="view_assess_progress container">
        <div className="row">
          <div className="col-lg-4">
            <div className="manage_assessment_students">
              <div className="ma_students_header">
                <h5>Manage Students</h5>
                <button className="btn btn-outline-primary">
                  Add Student <BsPlus />
                </button>
              </div>
              <div className="text-center my-3">
                <h5>Project Participants</h5>
              </div>
              <div className="ma_student_content_container">
                {
                  students.map(e => {
                    return <div 
                      key={e.id} 
                      onClick={ ()=> handleGetAssessment(e.student)}
                      className="ma_student_content">
                      <h4>{e.student.full_name}</h4>
                      <button
                        className="btn"
                        onClick={() => removeStudent(e.student.user_id)}>
                        <BsXLg />
                      </button>
                    </div>
                  })
                }
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="view_progress_header">
              {
                loading? <div className="center"> {show? <ClipLoader color="#0a6bfb" />:""}</div> :
              <h3>{active? `${current_student} - Project Progress` : ""}</h3>
              }
            </div>
            {phases !== undefined
              ? phases.map((phase) => (
                <Accordion key={phase.id} className="mb-2">
                  <Accordion.Item eventKey={phase.id}>
                    <Accordion.Header>{phase.name}</Accordion.Header>
                    <Accordion.Body>
                      <ul className="c_modules_breakdown_list">
                        {phase.tasks.map((t) => (
                          <li key={t.id}>
                            <div className=" d-flex justify-content-between align-items-center">
                              {t.task}
                              <div className="view_progress_dropdown center">
                                <span style={{lineHeight:"3.1",fontSize: "11px",marginRight:"10px"}}>{t.status}</span>
                                <Dropdown>
                                  <Dropdown.Toggle>
                                    <BsThreeDotsVertical />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {/* <Link className="dropdown-item" to={``}> */}
                                      Approve
                                    {/* </Link> */}
                                    {/* <Link className="dropdown-item" to=""> */}
                                      Needs attention
                                    {/* </Link> */}
                                    {/* <Link className="dropdown-item" to={``}> */}
                                      Done
                                    {/* </Link> */}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </li>
                        ))}
                        <div className="view_progress_down_btn mt-3">
                          <button 
                            onClick={ ()=>downloadFile(phase)}
                            className="btn btn-outline-primary d-flex align-items-center">
                            <BsFileEarmarkArrowDown className="me-2" /> Download Files
                          </button>
                        </div>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))
              : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default AssessmentProgress;
