import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/images/Rework-Academy-(Fill).png";
import {
  BsBarChartLine,
  BsBook,
  BsPeople,
  BsPersonWorkspace,
  BsBraces,
  BsCashCoin,
  BsPersonCheck,
  BsBell,
  BsPersonVcard,
  BsGear,
  BsFolder,
  BsBuildings,
} from "react-icons/bs";
import { useEffect, useState } from "react";

const Sidebar = () => {
  const [active, setActive] = useState(null);
  const location = useLocation();
  const links = [
    { id: 1, path: "/", title: "Dashboard", icon: <BsBarChartLine /> },
    { id: 2, path: "/students", title: "Students", icon: <BsPeople /> },
    { id: 3, path: "/batches", title: "Batches", icon: <BsFolder /> },
    { id: 4, path: "/branches", title: "Branches", icon: <BsBuildings /> },
    { id: 5, path: "/courses", title: "Courses", icon: <BsBook /> },
    { id: 6, path: "/assessments", title: "Assessments", icon: <BsBraces /> },
    { id: 7, path: "/modules", title: "Modules", icon: <BsPersonWorkspace /> },
    { id: 8, path: "/trainers", title: "Trainers", icon: <BsPersonWorkspace /> },
    { id: 9, path: "/payments", title: "Payments", icon: <BsCashCoin /> },
    { id: 10, path: "/attendance", title: "Attendance", icon: <BsPersonCheck /> },
    { id: 11, path: "/notifications", title: "Notifications", icon: <BsBell /> },
    { id: 12, path: "/referrals", title: "Referrals", icon: <BsPersonVcard /> },
    { id: 13, path: "/settigns", title: "Settings", icon: <BsGear /> },
  ];

  useEffect(() => {
    setActive(location.pathname);
  }, [location]);

  return (
    <div className="sidebar">
      <div className="sidebar_header">
        <img src={Logo} alt="Logo" />
        <h4>Admin</h4>
      </div>
      <div className="sidebar_content">
        <ul className="sidebar_menu">
          {links.map((link) => (
            <li key={link.id} className={`sidebar_list ${active === link.path ? "active" : ""}`}>
              <span>{link.icon}</span>
              <Link to={link.path} className={`sidebar_link ${active === link.path ? "active" : ""}`}>
                {link.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default Sidebar;
