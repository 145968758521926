import { useEffect, useState } from "react";
import { Navigation } from "../../components/navigation/Navigation";
import CreateBatch from "./CreateBatch";
import "./style.css";
import CreateSessions from "./CreateSessions";
import BatchesBreakdown from "./BatchesBreakdown";
import BatchService from "./BatchService";

function Batches() {
  const [batches, setBatches] = useState([
    {
      id: Math.floor(Math.random() * 10000000).toString(),
      name: "Batch 15",
      description: "Oct 2023",
      sessions: [
        { id: "1", session_name: "Weekday", start_date: "09/10/2023" },
        { id: "2", session_name: "Weekend", start_date: "14/10/2023" },
        { id: "3", session_name: "Virtual", start_date: "09/10/2023" },
      ],
    },
  ]);
  
  useEffect(()=>{

    BatchService
    .getBatches("sub")
    .then(res=>{
      setBatches(res)
    })

  },[]);

  return (
    <>
      <Navigation title="Manage Batches" />
      <div className="batches container">
        <div className="row">
          <div className="col-lg-4">
            <CreateBatch batches={batches} setBatches={setBatches} />
          </div>
          <div className="col-lg-4">
            <CreateSessions batches={batches} setBatches={setBatches} />
          </div>
          <div className="col-lg-4">
            <BatchesBreakdown batches={batches} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Batches;
