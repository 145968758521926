import { Navigation } from "../../components/navigation/Navigation";
import { useEffect, useState } from "react";
import "./style.css";
import { BranchTable } from "./BranchTable";
import { BsPlus } from "react-icons/bs";
import { Link } from "react-router-dom";
import BranchService from "./BranchService";

function Branches() {
  const [branches,setBranch] = useState([
    {
      id: 1,
      name: "Main Branch",
      country: "Nigeria",
      state: "FCT",
      district: "Area 1",
      address: "House 5, Block C, Aknaton Estate",
      rejected: 10,
      accepted: 500,
      students: 1000,
      trainers: 10,
      staffs: 20,
    },
  ]);

  useEffect(()=>{
    BranchService
    .getBranches()
    .then(res => {
      setBranch(res);
    })
  },[])

  return (
    <>
      <Navigation title="Manage Branches" />
      <div className="container">
        <div className="students_subheader">
          <Link to="/branches/create" className="btn btn-outline-primary">
            <span>Create Branch</span> <BsPlus />
          </Link>
          <div className="students_subheader_input">
            <input type="text" placeholder="Search" className="form-control shadow-none" />
          </div>
        </div>
      </div>
      <BranchTable branches={branches} />
    </>
  );
}

export default Branches;
