export const Card1 = ({ icon, title, content, setClick, active_card }) => {
  return (
    <div className="D1_card" onClick={setClick}>
      <div className="d-flex align-items-center justify-content-center">
        <span className="D1_card_icon">{icon}</span>
        <h5>{title}</h5>
      </div>
      <h2>{content}</h2>
    </div>
  );
};
