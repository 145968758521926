import { BsPerson, BsPlus, BsSearch } from "react-icons/bs";
import { Navigation } from "../../components/navigation/Navigation";
import "./style.css";
import { Link } from "react-router-dom";
import TrainersGrid from "./TrainersGrid";
import { useEffect, useState } from "react";
import SingleTrainer from "./SingleTrainer";
import TrainerService from "./TrainerService";


function Trainers() {
  const [trainers,setTrainer] = useState([
    {
      id: 1,
      first_name: "Jacob",
      last_name: "May",
      email: "nec.enim.nunc@protonmail.org",
      age: 52,
      gender: "male",
      phone: "(0121) 867 1463",
      employee_id: 74406109,
      address: "P.O. Box 195, 6849 Natoque St.",
      current_branch: "House 5, Block C, Aknaton Estate, Durumi, Area 1, Abuja.",
      img: <BsPerson />,
      performance: 53,
    }
  ]);
  const [activeTrainerId, setActiveTrainerId] = useState("");

  useEffect(()=>{

    TrainerService
    .getTrainers()
    .then(e =>{
      
      let trainers = e.map(d=>{
        return {
          
            id:d.id,
            first_name:d.first_name,
            last_name:d.last_name,
            email:d.email,
            age:d.age,
            gender:d.gender,
            phone:d.phone,
            employee_id:d.emp_code,
            address:d.addr,
            current_branch:d.branch,
            img:d.img ? d.img : <BsPerson />,
            performance:d.performance
          
        }
      })

      setTrainer(trainers)
    })


  },[])

  return (
    <>
      <Navigation title="Manage Trainers" />
      <div className="trainers container">
        <div className="trainers_header">
          <Link to="/trainers/create_new" className="btn btn-outline-secondary">
            Create Trainer
            <BsPlus />
          </Link>
          <div className="trainers_header_group">
            <input type="text" placeholder="Search..." className="form-control shadow-none" />
            <button>
              <BsSearch />
            </button>
          </div>
        </div>
        <div className="trainers_container">
          <div className="row">
            <TrainersGrid trainers={trainers} activeTrainerId={activeTrainerId} setActiveTrainerId={setActiveTrainerId} />
            {activeTrainerId ? <SingleTrainer trainers={trainers} activeTrainerId={activeTrainerId} setActiveTrainerId={setActiveTrainerId} /> : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default Trainers;
