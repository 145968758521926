import { useState,useEffect } from "react";
import AttendanceService from "./AttendanceService";

function AttendanceBatches({ setBatchId, setSession }) {
  const [batches,setBatches] = useState([
    { id: "1", name: "Batch 11", sessions: ["Weekday", "Weekend", "Custom"] },
    { id: "2", name: "Batch 12", sessions: ["Weekday", "Weekend", "Custom"] },
    { id: "3", name: "Batch 13", sessions: ["Weekday", "Weekend", "Custom"] },
    { id: "4", name: "Batch 14", sessions: ["Weekday", "Weekend", "Custom"] },
    { id: "5", name: "Batch 15", sessions: ["Weekday", "Weekend"] },
  ]);


  const handleBatch = (e, new_session) => {
 
    setBatchId((prev) => (prev = e.target.value));
    setSession((prev) => (prev = new_session));

  };

  useEffect(()=>{

    AttendanceService
    .getBatches("sub")
    .then(res=>{

      let data = res.map(e=>({
        id:e.id,
        name:e.name,
        sessions:[...e.sessions.map(k=>(k.session_name))]
      }));
      setBatches(data)

    });

  },[]);

  return (
    <div className="col-lg-4">
      <div className="attendance_contents attendance_batches">
        <div className="attendance_batches_header">
          <h5>Batches</h5>
          <p>Select Batch</p>
        </div>
        <div className="attendance_batches_view">
          {batches.length
            ? batches.map((batch) => (
                <select 
                  key={batch.id} 
                  className="form-control shadow-none" 
                  onChange={(e) => handleBatch(e, batch.id)}>
                    <option value=''>{batch.name}</option>
                    {batch.sessions.map((session, i) => (
                      <option value={`${batch.name}-${session}`} key={i}>
                        {batch.name} - {session}
                      </option>
                    ))}
                </select>
              ))
            : null}
        </div>
      </div>
    </div>
  );
}

export default AttendanceBatches;
