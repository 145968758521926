import { Dropdown } from "react-bootstrap";
import { Loading } from "../../components/loading/Loading";
import { Link } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";

function AssessmentsTable({ assessments, loading, errMsg }) {
  return (
    <div className="table_container">
      <table className="table table-bordered">
        <thead className="table-secondary">
          <tr>
            <th>Name</th>
            <th>Category</th>
            <th>Duration</th>
            <th>Description</th>
            <th>Attachment</th>
            <th>Type</th>
            <th>Participants</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {assessments.length ? (
            assessments.map((assessment) => (
              <tr key={assessment.id}>
                <td>{assessment.name}</td>
                <td>{assessment.category}</td>
                <td>{assessment.duration}</td>
                <td>{assessment.description}</td>
                <td className={assessment.attachment ? "bg-primary text-white" : "bg-danger text-white"}>{assessment.attachment ? "True" : "False"}</td>
                <td>{assessment.type}</td>
                <td>{assessment.participants}</td>
                <td className="my_dropdown">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <BsChevronDown />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Link className="dropdown-item" to={`/assessment/update/${assessment.id}`}>
                        Update Project
                      </Link>
                      <Link className="dropdown-item" to="/projects/assign">
                        Assign
                      </Link>
                      <Link className="dropdown-item" to={`/assessments/${assessment.id}/tasks`}>
                        Manage Task
                      </Link>
                      <Link className="dropdown-item" to={`/assessments/delete/${assessment.id}`}>
                        Delete Assessment
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))
          ) : loading === true ? (
            <tr className="table_loading">
              <td colSpan={11}>
                <Loading />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={11}>{errMsg}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default AssessmentsTable;
