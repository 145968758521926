import React, { useState } from "react";
import { Navigation } from "../../components/navigation/Navigation";
import { BsCloudUpload } from "react-icons/bs";
import BeatLoader from "react-spinners/BeatLoader";
import CourseService from "./CourseService";
import { toast } from 'react-toastify';

export default function CreateCourse() {

  let initial_state = {
    alias: "",
    name: "",
    course_code: "",
    duration: "",
    monthly_fee: "",
    acceptance_fee: "20,000",
    min_percent_attendance: 50,
    max_percent_attendance: 90,
    total_fee: "",
    total_fee_selfpace:"",
    descp: ""
  }
  let course_id;

  let [loading, setLoading] = useState(false);
  let [banner, setBanner] = useState("");
  let [thumbnail, setThumbnail] = useState("");
  let [data, setData] = useState(initial_state);

  let handleOnchange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  let handleCreateCourse = (e) => {
    e.preventDefault();

    setLoading(true);

    CourseService.create(data)
      .then((_) => {

        if (_.type === 'EXISTS') {
          setLoading(false);
          toast(_.msg, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }

        if (_.type === "SUCCESS") {

          course_id = _.data.id;
          CourseService.uploadImage(course_id, thumbnail, banner)
            .then((_) => {

              setLoading(false);
              setThumbnail("");
              setBanner("");
              setData(initial_state);

              toast(_.msg, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });


            })
        }

      })

  }

  return (
    <>
      <Navigation title="Create Course" />
      <div className="container">
        <form className="form course_form" onSubmit={handleCreateCourse}>
          <div className="row">
            <div className="col-lg-6">
              <label htmlFor="">Course Name</label>
              <input
                type="text"
                name="name"
                value={data.name}
                onChange={handleOnchange}
                placeholder="Enter Course name"
                className="form-control shadow-none" />
            </div>
            <div className="col-lg-6">
              <label htmlFor="">Duration</label>
              <input
                type="text"
                name="duration"
                value={data.duration}
                onChange={handleOnchange}
                placeholder="Enter Course Duration"
                className="form-control shadow-none" />
            </div>
            <div className="col-lg-12">
              <label htmlFor="">Course Code</label>
              <input
                type="text"
                placeholder="Enter Course Code"
                name="course_code"
                value={data.course_code}
                onChange={handleOnchange}
                className="form-control shadow-none" />
            </div>
            <div className="col-lg-12">
              <label htmlFor="">Alias</label>
              <input
                type="text"
                placeholder="Enter Alias"
                name="alias"
                value={data.alias}
                onChange={handleOnchange}
                className="form-control shadow-none" />
            </div>

            <div className="col-lg-6">
              <label htmlFor="">Total Fee</label>
              <input
                type="text"
                name="total_fee"
                value={data.total_fee}
                onChange={handleOnchange}
                placeholder="Enter Total Fee"
                className="form-control shadow-none" />
            </div>
            <div className="col-lg-6">
              <label htmlFor="">Total Selfpace Fee</label>
              <input
                type="text"
                name="total_fee_selfpace"
                value={data.total_fee_selfpace}
                onChange={handleOnchange}
                placeholder="Enter Total Fee"
                className="form-control shadow-none" />
            </div>
            <div className="col-lg-6">
              <label htmlFor="">Monthly Fee</label>
              <input
                type="text"
                value={data.monthly_fee}
                name="monthly_fee"
                onChange={handleOnchange}
                placeholder="Enter Monthly Fee"
                className="form-control shadow-none" />
            </div>
            <div className="col-lg-12">
              <label htmlFor="">Description</label>
              <textarea
                name="descp"
                value={data.descp}
                onChange={handleOnchange}
                className="form-control shadow-none"></textarea>
            </div>
            <div className="col-lg-6 course_form_upload">
              <label htmlFor="course_thubnail">
                <BsCloudUpload />
                <span>{thumbnail ? thumbnail.name : "Upload Thumbnail Image"}</span>
              </label>
              <input
                type="file"
                onChange={(e) => setThumbnail(e.target.files[0])}
                id="course_thubnail" />
            </div>
            <div className="col-lg-6 course_form_upload">
              <label htmlFor="course_bannerImg">
                <BsCloudUpload />
                <span>{banner ? banner.name : "Upload Banner Image"}</span>
              </label>
              <input
                type="file"
                onChange={(e) => setBanner(e.target.files[0])}
                id="course_bannerImg" />
            </div>
          </div>
          <div className="course_form_btn">
            <button
              className="btn btn-outline-primary"
              disabled={loading ? "disabled" : null}
            >
              {loading ? <BeatLoader color="#0a6bfb" /> : " Create Course"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
