import { BsChevronDown } from "react-icons/bs";
import { Loading } from "../../components/loading/Loading";
import { useStudentContext } from "../../hooks/useStudentContext";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function StudentTable({ students, errMsg }) {
  const { loading } = useStudentContext();
  return (
    <div className="table_container">
      <table className="table table-bordered">
        <thead className="table-secondary">
          <tr>
            <th>Student ID</th>
            <th>Student Name</th>
            <th>Status</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Gender</th>
            {/* <th>DOB</th> */}
            <th>Age</th>
            <th>Batch</th>
            <th>Course</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {students.length ? (
            students.map((student) => (
              <tr key={student.id}>
                <td>{student.student_id}</td>
                <td>
                  {student.first_name} {student.last_name}
                </td>
                <td className={`${student.status === "Interests" ? "bg-warning text-white" : ""}`}>{student.status}</td>
                <td>{student.email}</td>
                <td>{student.phone}</td>
                <td>{student.gender}</td>
                {/* <td>{student.birth_date}</td> */}
                <td>{student.age}</td>
                <td>{student.batch}</td>
                <td>{student.course.name || student.course}</td>
               
                  <td className="my_dropdown">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <BsChevronDown />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      
                      <Link className="dropdown-item" to={`/projects/assign?uid=${student.id}`}>
                        Assign Project
                      </Link>
                      <Link className="dropdown-item">
                        View Payment
                      </Link>
                      <Link className="dropdown-item">
                       Check Status
                      </Link>
                      <Link className="dropdown-item">
                       Get Attendance
                      </Link>
                      <Link className="dropdown-item">
                        Edit Account
                      </Link>
                      <Link className="dropdown-item">
                        Delete Account
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))
          ) : loading === true ? (
            <tr className="table_loading">
              <td colSpan={11}>
                <Loading />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={11}>{errMsg}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
