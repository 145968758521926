import React from "react";
import { Loading } from "../../components/loading/Loading";
import { BsChevronDown } from "react-icons/bs";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function CourseTable({ courses, loading, errMsg }) {
  return (
    <div className="table_container">
      <table className="table table-bordered">
        <thead className="table-secondary">
          <tr>
            <th>Course Name</th>
            <th>Monthly Fee</th>
            <th>Total Fee</th>
            <th>Total Selfpace Fee</th>
            <th>Duration</th>
            <th>Total Enrolled</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {courses.length ? (
            courses.map((course) => (
              <tr key={course.id}>
                <td>{course.name}</td>
                <td>{course.monthly_fee}</td>
                <td>{course.total_fee}</td>
                <td>{course.total_fee_selfpace}</td>
                <td>{course.duration}</td>
                <td>{course.total_enrolled}</td>
                <td className="my_dropdown">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <BsChevronDown />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Link className="dropdown-item" to={`/courses/${course.id}/update`}>
                        Update Course
                      </Link>
                      <Link className="dropdown-item" to={`/courses/${course.id}/manage_modules`}>
                        Manage Modules
                      </Link>
                      <Link className="dropdown-item" to="/courses/delete_course">
                        Delete Course
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))
          ) : loading === true ? (
            <tr className="table_loading">
              <td colSpan={11}>
                <Loading />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={11}>{errMsg}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
