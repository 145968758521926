import { Navigation } from "../../components/navigation/Navigation";
import { Link } from "react-router-dom";
import { BsPlus } from "react-icons/bs";
import { useEffect, useState } from "react";
import { PaymentTable } from "./PaymentTable";
import PaymentService from "./PaymentService";
import config from "../config"
import Paginate from "../../hooks/usePaginate";


function Payments() {

  let init_data = [
    {
      id: 1,
      student_id: 1,
      full_name: "David Archuleta",
      paid: "56,900",
      currency: "₦",
      method: "CASH",
      status: "Approved",
      date_paid: "02/11/2023"
    },
  ]
  let init_url = ((config.MODE === "DEV") ? config.DEV : config.PROD).concat("/payments").query({ sort: "desc", summary: 1, page: 1, limit: 15 });
  
  let [paginate, setPaginate] = useState("");
  let [payments, setPayment] = useState(init_data);
  let [url,setUrl] = useState(init_url);

  let handlePageClick = async (e) => {

    let { selected } = e;
    let page = `page=${paginate?.page}`;
    let selected_page = selected + 1;
    let current_page = "page=" + selected_page;
    let  new_url = url.replace(page, current_page);
  
    setUrl(new_url);

  }

  useEffect(() => {

    PaymentService
      .getPayments(url)
      .then(res => {

        setPayment(res.data);
        setPaginate(res.paginate);

      });

  }, [url]);

  return (
    <>
      <Navigation title="Manage Payments" />
      <div className="container">
        <div className="students_subheader">
          <Link to="/payments/create" className="btn btn-outline-primary">
            <span>Create Payment</span> <BsPlus />
          </Link>
          <div className="students_subheader_input">
            <input type="text" placeholder="Search" className="form-control shadow-none" />
          </div>
        </div>
      </div>
      <Paginate 
        paginate={paginate}
        handlePageClick={handlePageClick}
        setUrl={setUrl}
        url={url} />
      <PaymentTable payments={payments} />
    </>
  );
}

export default Payments;
